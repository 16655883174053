import React from 'react';
import Api from '../../api/reporting';
import LoadingOverlay from '../loading-overlay';
import { GetShortDateString } from '../../utils/date';
import DatePicker from "react-datepicker";
import "../../sass/elements/_datepicker.css";

export default class ReportingModel extends React.Component {
   constructor (props) {
      super(props)
      this.state = {
         loading: false,
         modelCount: '',
         cadModelCount: '',
         cadModelTotalCount: '',
         mouldModelCount: '',
         printableCount: '',
         notPrintableCount: '',
         repairNotNeededCount: '',
         repairNeededCount: '',
         repairSuccessCount: '',
         repairFailedCount: '',
         cadFilesUploadedCount: '',
         cadFilesCompletedAnalysisCount: '',
         accountNo: '',
         startDate: new Date('01/01/2021'),
         endDate: new Date(),
      };

      this.changeStartDate = this.changeStartDate.bind(this);
      this.changeEndDate = this.changeEndDate.bind(this);
   }

   componentDidMount() {
      if (this.props.appState.accessToken) {
         this.getReports();
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (this.props.appState.accessToken && prevProps.appState.accessToken !== this.props.appState.accessToken) {
         this.getReports();
      }
   }

   changeStartDate(date) {
      this.setState({
        startDate: date
      });
   };

   changeEndDate(date) {
      this.setState({
        endDate: date
      });
   };
   
   onAccountNoChange = (e) => {
      this.setState({ accountNo: e.target.value});
   };

   onFilter = async () => {
      if (this.state.startDate > this.state.endDate) return;
      this.getReports();
   };

   onClear = async () => {
      await this.setState({
         accountNo: '',
         startDate: new Date('01/01/2021'),
         endDate: new Date(),         
      })
      this.getReports();
   };

   async getReports() {
      this.setState({ loading: true });

      var reportingFilter = {
         StartDate: GetShortDateString(this.state.startDate),
         EndDate: GetShortDateString(this.state.endDate),
         AccountNo: this.state.accountNo,
      }

      var modelReport = await Api.models(this.props.appState.accessToken, reportingFilter);
      this.setState({
         loading: false,
         modelCount: modelReport.modelCount,
         cadModelCount: modelReport.cadModelCount,
         cadModelTotalCount: modelReport.cadModelTotalCount,
         mouldModelCount: modelReport.mouldModelCount,
         printableCount: modelReport.printableCount,
         notPrintableCount: modelReport.notPrintableCount,
         repairNotNeededCount: modelReport.repairNotNeededCount,
         repairNeededCount: modelReport.repairNeededCount,
         repairSuccessCount: modelReport.repairSuccessCount,
         repairFailedCount: modelReport.repairFailedCount,
         cadFilesUploadedCount: modelReport.cadFilesUploadedCount,
         cadFilesCompletedAnalysisCount: modelReport.cadFilesCompletedAnalysisCount,
      });
   }

   render() {
      
      const dateCheck = this.state.startDate > this.state.endDate;
      const dateErrorRender = (
         <div className='row d-flex justify-content-center'>
            {dateCheck && <p className='text-orange'><strong>Start Date cannot be later than the End Date</strong></p>}
         </div>
      );

      const filterRender = (
         <>
            <div className='row d-flex align-items-center'>
                <p className='col-6 text-right'><strong>Start Date: </strong></p>
                <DatePicker className='data' dateFormat={'dd/MM/yyyy'} selected={this.state.startDate} onChange={this.changeStartDate} showYearDropdown dateFormatCalendar="MMMM" />
            </div>
            <div className='row d-flex align-items-center'>
                <p className='col-6 text-right'><strong>End Date: </strong></p>
                <DatePicker className='data' dateFormat={'dd/MM/yyyy'} selected={this.state.endDate} onChange={this.changeEndDate} showYearDropdown dateFormatCalendar="MMMM" />
            </div>  
            <div className='row d-flex align-items-center justify-content-center'>
               <p className='col-6 text-right'><strong>Account Number:</strong></p>
               <div className='col-6 p-0'>
                <input
                    name='accountNo'
                    className='data'
                    value={this.state.accountNo}
                    onChange={this.onAccountNoChange}
                    maxLength={6}
                />
               </div>
               <div className='p-4'>
                    <button className={`${dateCheck ? 'btn btn-primary cng3d-general-button disabled' : 'btn btn-primary cng3d-general-button highlighted'}`} onClick={this.onFilter}>Filter</button>
                    <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.onClear}>Clear</button>
               </div>
            </div>
         </>
      )

      return (
        <div className='container-fluid'>
            <LoadingOverlay loading={this.state.loading} />
            <div className='row'>
                <div className='portal-container d-flex align-items-center'>

                    {/* Detail Section */}
                    <div className='col-12 portal-contents p-4 absolute'>

                        {!this.state.loading &&
                            <div className='col-12'>
                                <h3><strong>Portal Usage</strong></h3>

                                {filterRender}
                                {dateErrorRender}

                                <h2>CAD Models Created</h2>
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>Total:</strong></p>
                                    <p className='col-6'>{this.state.cadModelCount}</p>
                                </div>   
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>No Repair Needed:</strong></p>
                                    <p className='col-6'>{this.state.repairNotNeededCount}</p>
                                </div>    
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>Repair Needed:</strong></p>
                                    <p className='col-6'>{this.state.repairNeededCount}</p>
                                </div>  

                                <h2>CAD Files Uploaded</h2>
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>Total CAD Files:</strong></p>
                                    <p className='col-6'>{this.state.cadFilesUploadedCount}</p>
                                </div>   
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>Completed Analysis:</strong></p>
                                    <p className='col-6'>{this.state.cadFilesCompletedAnalysisCount}</p>
                                </div>    
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>Failed Analysis:</strong></p>
                                    <p className='col-6'>{this.state.cadFilesUploadedCount-this.state.cadFilesCompletedAnalysisCount}</p>
                                </div>

                                <h4>Abandoned Uploads</h4>
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>No Analysis Problem:</strong></p>
                                    <p className='col-6'>{this.state.cadFilesCompletedAnalysisCount-this.state.cadModelCount}</p>
                                </div>    
                                <div className='row d-flex align-items-center'>
                                    <p className='col-6 text-right'><strong>Total:</strong></p>
                                    <p className='col-6'>{this.state.cadFilesUploadedCount - this.state.cadModelCount}</p>
                                </div>             
        
                            </div>
                        }
    
                    </div>         

                </div>
            </div>
        </div>
      );
   }
}