import Api from "./api";

export default class ApiCustomerAccount extends Api {
  static async createCustomerAccount(account) {
    const data = await this.post("/api/Customer/", null, account, true);
    return data;
  }

  static async getCustomerAccounts(accessToken, searchObject) {
    const data = await this.post(
      "/api/Customer/search",
      accessToken,
      searchObject,
      true
    );
    return data;
  }

  static async exportCustomerAccounts(accessToken, searchObject) {
    await this.postWithDownload(
      "/api/Customer/export",
      accessToken,
      searchObject,
      "customerAccountExport.xlsx"
    );
  }

  static async getCustomerAccount(accessToken, id) {
    const data = await this.get(`/api/Customer/${id}`, accessToken);
    return data;
  }

  static async getCustomerErpData(accessToken, id) {
    const data = await this.get(`/api/Erp/getCustomerInfo/${id}`, accessToken);
    return data;
  }

  static async getCustomerAccountOfLoggedInUser(accessToken) {
    const data = await this.get("/api/Customer/me", accessToken);
    return data;
  }

  static async updateCustomerLastLoginDate(accessToken, id) {
    const data = await this.put(
      `/api/Customer/updateLoginDate/${id}`,
      accessToken
    );
    return data;
  }

  static async getPricingAttributes(accessToken) {
    const data = await this.get("/api/Customer/pricingAttributes", accessToken);
    return data;
  }

  static async updateCustomer(accessToken, object) {
    const success = await this.put("/api/Customer/", accessToken, object);
    return success;
  }

  static async updateCustomerState(accessToken, id, state) {
    const success = await this.put(
      `/api/Customer/state/${id}`,
      accessToken,
      state
    );
    return success;
  }

  static async updateCustomerPricingAttribute(
    accessToken,
    customerId,
    attributeId,
    attributeValue
  ) {
    const success = await this.put(
      `/api/Customer/pricingAttribute/${customerId}/${attributeId}/${attributeValue}`,
      accessToken
    );
    return success;
  }

  static async addCustomerNote(accessToken, customerId, noteText) {
    const data = await this.post(
      `/api/Customer/note/${customerId}`,
      accessToken,
      noteText
    );
    return data;
  }

  static async syncErpData(accessToken, customerId) {
    const data = await this.get(
      `/api/Erp/updateCustomer/${customerId}`,
      accessToken
    );
    return data;
  }
}
