import Api from "./api";

export default class ApiFile extends Api {
  static async uploadCadModel(accessToken, customerId, file) {
    const data = await this.upload(
      `/api/file/cad/customer/${customerId}`,
      accessToken,
      file,
      true
    );
    return data;
  }

  static async uploadGenericCadModel(accessToken, file) {
    const data = await this.upload(
      `/api/file/cad/customer/`,
      accessToken,
      file,
      true
    );
    return data;
  }

  static async uploadImage(accessToken, modelId, file) {
    const data = await this.upload(
      `/api/file/image/model/${modelId}`,
      accessToken,
      file
    );
    return data;
  }

  static async uploadCadImage(accessToken, id, file) {
    const data = await this.upload(
      `/api/file/image/cadModel/${id}`,
      accessToken,
      file
    );
    return data;
  }

  static async replaceCadModel(accessToken, modelId, file) {
    const data = await this.upload(
      `/api/file/cad/model/${modelId}`,
      accessToken,
      file
    );
    return data;
  }

  static async getCustomerFileInfo(accessToken, id) {
    const data = await this.get(`/api/file/${id}?ipSas=false`, accessToken);
    return data;
  }

  static async getAnalysisUpdate(accessToken, id) {
    const data = await this.get(`/api/file/analysisStatus/${id}`, accessToken);
    return data;
  }

  static async download(accessToken, buildQueueIds) {
    try {
      let headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const body = JSON.stringify(buildQueueIds);
      const result = await fetch(`${this.apiHost}/api/file/download/`, {
        headers,
        method: "post",
        body,
      });
      if (!result.ok) {
        const error = await result.json();
        this.handleError(error);
      } else return result;
    } catch (error) {
      //this.handleError(error);
    }
  }
}
