import React from "react";
import LoadingCube from "../../elements/loading-cube";
import ApiFile from "../../api/file";
import ApiCadAnalysis from "../../api/cad-analysis";

const signalR = require("@microsoft/signalr");

const AnalysisSteps = {
  NotStarted: 0,
  Analysis: 1,
  Repair: 2,
  PostRepairAnalysis: 3,
  GenerateThumbnail: 4,
  Complete: 5,
};

export default class ModelAdd2Analyse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      analysisId: null,
      analysisStatus: "",
      connection: null,
    };

    this.currentAnalysisStep = AnalysisSteps.NotStarted;
  }

  // Establish SignalR connection
  configureSignalRConnection = () => {
    let connection = new signalR.HubConnectionBuilder()
      .withUrl(
        process.env.REACT_APP_HUB_HOST +
          `/AnalysisHub?access_token=${this.props.appState.accessToken}`,
        {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        }
      )
      .withAutomaticReconnect()
      .build();

    connection.on("ReceiveMessage", (message) => {
      if (message.customerFileId === this.props.fileId) {
        this.props.changeAnalysisId(message);
        switch (this.currentAnalysisStep) {
          // Handle Analysis Response
          case AnalysisSteps.Analysis:
            if (!message.analysisFailed) {
              this.setState({ analysisStatus: "Analysis Complete" });

              if (message.repairNeeded) {
                this.repair();
              } else {
                this.setState({ analysisStatus: "No Repair Needed" });
                this.generateThumbnail();
              }
            }
            break;

          // Handle Repair Response
          case AnalysisSteps.Repair:
            this.postRepairAnalysis();
            break;

          case AnalysisSteps.PostRepairAnalysis:
            if (message.repairSuccess) {
              this.setState({ analysisStatus: "Repair Complete" });
            } else {
              this.setState({ analysisStatus: "Repair Failed" });
            }

            this.generateThumbnail();
            break;

          // Handle Generate Thumbnail Response
          case AnalysisSteps.GenerateThumbnail:
            this.completeAnalysis();
            break;

          default:
            break;
        }
      }
    });

    this.setState({ connection });
  };
  componentDidMount = async () => {
    this.props.changeAnalysingState(true);
    this.configureSignalRConnection();
  };

  componentDidUpdate(prevProps, prevState) {
    // Start signalR hub connection and add listeners
    const startHubConnection = async () => {
      try {
        if (this.state.connection) {
          await this.state.connection.start();

          if (this.currentAnalysisStep === AnalysisSteps.NotStarted) {
            this.analysis();
          }
        }
      } catch (e) {
        console.log("Connection failed: ", e);
        setTimeout(startHubConnection, 5000);
      }
    };

    if (prevProps.accessToken !== this.props.accessToken) {
      this.configureSignalRConnection();
    }

    if (prevState.connection !== this.state.connection) {
      startHubConnection();
    }
  }

  // Stop SignalR connection on unmount
  componentWillUnmount() {
    if (this.state.connection) {
      this.state.connection.stop();
      this.setState({ connection: null });
    }
  }

  // Analyse file
  analysis = async () => {
    if (!this.props.analysisId.uploadFailed) {
      this.currentAnalysisStep = AnalysisSteps.Analysis;
      this.setState({ analysisStatus: "Analysing File" });

      await ApiCadAnalysis.analysis(
        this.props.appState.accessToken,
        this.props.fileId
      );
    } else {
      await this.generateThumbnail();
    }
  };

  // Repair file
  repair = async () => {
    this.currentAnalysisStep = AnalysisSteps.Repair;
    this.setState({ analysisStatus: "Repairing File" });

    await ApiCadAnalysis.repair(
      this.props.appState.accessToken,
      this.props.fileId
    );
  };

  postRepairAnalysis = async () => {
    this.currentAnalysisStep = AnalysisSteps.PostRepairAnalysis;
    await ApiCadAnalysis.analysis(
      this.props.appState.accessToken,
      this.props.fileId
    );
  };

  // Generate thumbnail for file
  generateThumbnail = async () => {
    this.currentAnalysisStep = AnalysisSteps.GenerateThumbnail;
    this.setState({ analysisStatus: "Saving Printable File" });

    await ApiCadAnalysis.generateThumbnail(
      this.props.appState.accessToken,
      this.props.fileId
    );
  };

  // Store results of completed analysis
  completeAnalysis = async () => {
    this.currentAnalysisStep = AnalysisSteps.Complete;
    let results = await ApiFile.getCustomerFileInfo(
      this.props.appState.accessToken,
      this.props.fileId
    );

    if (this.props.analysisId.uploadFailed) {
      results.cadAnalysisResults = this.props.analysisId;
    }

    await this.props.changeAnalysingState(false, results.cadAnalysisResults);

    if (
      (results.cadAnalysisResults.repairNeeded ||
        results.cadAnalysisResults.startRepair) &&
      results.cadAnalysisResults.repairSuccess
    ) {
      await this.props.changeFileUri(results.fileAccessPath);
    }

    this.props.changePage();
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="portal-container d-md-flex justify-content-center mt-5 mt-md-0">
            {/* Model Viewer */}
            <div className="col-12 col-md-6 portal-contents p-4 mt-5 mt-sm-0 align-items-center text-center">
              <LoadingCube text={this.state.analysisStatus} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
