export function GetShortDateString(dateString) {
   if (dateString === undefined) {
      dateString = '1900-01-01';
   }
   const d = new Date(dateString);
   return d.toLocaleDateString('en-GB');   
   // const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
   // const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
   // const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

   // return `${da}-${mo}-${ye}`;
}

export function GetShortDateTimeString(dateString) {
   if (dateString === undefined) {
      dateString = '1900-01-01';
   }
   const d = new Date(dateString);
   return d.toLocaleString('en-GB').replace(',','');   
}

export function GetShortTimeString(dateString) {
   if (dateString === undefined) {
      dateString = '1900-01-01';
   }
   const d = new Date(dateString);
   return d.toLocaleTimeString('en-GB');   
}
