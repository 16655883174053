import React from 'react';
import {Link} from "react-router-dom";

export default class Error extends React.Component {
   render() {
      return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='portal-container d-flex align-items-center'>

                    {/* Detail Section */}
                    <div className='col-12 portal-contents p-4 absolute'>
    
                        <div className='col-12'>
                            <h3><strong>Error</strong></h3>                       
                        </div>

                        <p className='col-12 p-4'>Unfortunately an error has occured which couldn't be handled, please try again to complete the action.</p>
                        <p className='col-12 p-4'>If this error persists, please contact groupdigital@cooksongold.com with a screenshot of the error details below.</p>

                        <h2 className='col-12 p-4'><strong>Error Details</strong></h2>
                        <h4 className='col-12'><strong>Error code:</strong> {this.props.match.params.id}</h4>
                        <h4 className='col-12'><strong>Error message:</strong> {this.props.match.params.message}</h4>

                        <div className='col-12 p-5 d-flex justify-content-center'>
                            <Link to='/'>
                                <button className='btn btn-primary cng3d-general-button highlighted'>Return Home</button>
                            </Link>
                        </div>
    
                    </div>           

                </div>
            </div>
        </div>
      );
   }
}
