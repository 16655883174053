import React from 'react';
import { Prompt } from 'react-router-dom';

import Api from '../../api/customer-account';
import LoadingOverlay from '../loading-overlay';

import NotesList from '../../elements/notes-list';
import { GetShortDateString } from '../../utils/date';

export default class AccountLiveDetail extends React.Component {
   state = {
      loading: false,
      changed: false,
      updated: false,
      isValid: true,
      userNote: '',
      customer: null,
   };

   componentDidMount() {
      if (this.props.appState.accessToken) {
         this.getAccount();
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (this.props.appState.accessToken && prevProps.appState.accessToken !== this.props.appState.accessToken) {
         this.getAccount();
      }
   }

   onCheckboxChange = async (e) => {
      const { name } = e.target;
      this.setState({ [name]: e.target.checked, changed: true });
   };

   onNewNoteAdded = () => {
      this.getAccount();
   };

   async getAccount() {
      this.setState({ loading: true });
      const a = await Api.getCustomerAccount(this.props.appState.accessToken, this.props.match.params.id);     
      console.log(a) 
      this.setState({
         loading: false,
         customer: a,
      });
   }

   toggleCustomerState = async () => {
      this.setState({ loading: true });
      const newState = { customerState: this.state.customer.customerState === 'Live' ? 'Deactivated' : 'Live', reason: '' };
      await Api.updateCustomerState(this.props.appState.accessToken, this.state.customer.customerId, newState);
      this.setState({ loading: false, updated: true });
      this.getAccount();
   };

   render() {
      return (
         <React.Fragment>
            <Prompt when={this.state.changed} message='You have unsaved changes, are you sure you want to leave?' />

                <div className='container-fluid'>
                    <LoadingOverlay loading={this.state.loading} />
                    <div className='row'>
                        <div className='portal-container d-flex align-items-center'>

                            {/* Detail Section */}
                            <div className='col-12 portal-contents p-4 absolute'>
            
                                <div className='col-12'>
                                    <h3><strong>Customer Details</strong></h3>

                                    <div className='row d-flex align-items-center'>
                                        <p className='col-6 text-right'><strong>Account Number:</strong></p>
                                        <p className='col-6'> {this.state.customer ? this.state.customer.erpCustomerReference : ''}</p>
                                    </div>   

                                    <div className='row d-flex align-items-center'>
                                        <p className='col-6 text-right'><strong>Name:</strong></p>
                                        <p className='col-6'> {this.state.customer ? this.state.customer.erpCustomerName : ''}</p>
                                    </div>    

                                    <div className='row d-flex align-items-center'>
                                        <p className='col-6 text-right'><strong>Portal Customer Since:</strong></p>
                                        <p className='col-6'> {this.state.customer ? GetShortDateString(this.state.customer.createdDate) : ''}</p>
                                    </div>    

                                    <div className='row d-flex align-items-center'>
                                        <p className='col-6 text-right'><strong>Portal Last Used:</strong></p>
                                        <p className='col-6'> {this.state.customer ? GetShortDateString(this.state.customer.lastLoginDate) : ''}</p>
                                    </div>                      
            
                                </div>

                                {this.state.customer &&
                                    <div className='row p-4 d-flex justify-content-center'>
                                        {this.props.appState.customerId === this.state.customer.customerId ? (
                                            <p className='text-orange'>You are Impersonating this account to view &amp; add models</p>
                                        ) : (
                                            <button
                                            className='btn btn-primary cng3d-general-button highlighted'                      
                                            onClick={() => {
                                                this.props.onImpersonate(
                                                    this.state.customer.customerId,
                                                    `${this.state.customer.erpCustomerName} (${this.state.customer.erpCustomerReference})`
                                                );
                                                return false;
                                            }}
                                            >
                                            Impersonate this customer to view &amp; add models
                                            </button>
                                        )}
                                    </div>
                                }

                                <NotesList
                                    appState={this.props.appState}
                                    customerId={this.props.match.params.id}
                                    notes={this.state.customer ? this.state.customer.adminNotes : null}
                                    refreshAccount={this.onNewNoteAdded}
                                />

            
                            </div>         

                        </div>
                    </div>
                </div>
         </React.Fragment>
      );
   }
}
