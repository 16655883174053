import React from "react";
import AuthProvider from "./AuthProvider";

import "./sass/cng3d-core.scss";

import { HashRouter as Router, Switch, Route } from "react-router-dom";

import ModelCategoryManagement from "./components/model/category-management";
import ModelAddBase from "./components/model/add-base";
import CustomerModelList from "./components/model/customer-list";
import GenericModelList from "./components/model/cooksongold-3d-list";
import ModelDetail from "./components/model/detail";
import AccountListLive from "./components/account/list-live";
import AccountLiveDetail from "./components/account/detail-live";
import AccountAdminList from "./components/account/list-admin";
import Login from "./components/login";
import Home from "./components/home";
import Menu from "./components/menu";
import ApiAdmin from "./api/admin";
import ApiCustomerAccount from "./api/customer-account";
import ApiLists from "./api/lists";
import Error from "./components/error";
import LoadingOverlay from "./components/loading-overlay";
import PageNotFound from "./page-not-found";
import ReportsModel from "./components/reports/model";

import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

history.listen((location, action) => {
  window.scrollTo(0, 0);
});

class App extends React.Component {
  state = {
    loading: false,
    showRegister: false,
    appState: {
      groupsOfLoggedInUser: null,
      customerId: null,
      customerName: null,
      accessToken: null,
      materials: null,
      modelCategories: null,
      pageSize: 20,
    },
    imageIndex: 0,
    crossfadeIntervalId: 0,
  };

  componentDidMount() {
    const crossfadeIntervalId = setInterval(this.changeImage, 5000);
    this.setState({ crossfadeIntervalId });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tokenResponse !== this.props.tokenResponse ||
      prevProps.appState !== this.props.appState
    ) {
      let customerId = this.state.appState.customerId;

      this.setState({ loading: true });
      const groupsOfLoggedInUser = await ApiAdmin.getGroupsOfLoggedInUser(
        this.props.tokenResponse.accessToken
      );
      if (
        !groupsOfLoggedInUser.find(
          (el) => el.groupName === process.env.REACT_APP_ADMIN_GROUP
        )
      ) {
        customerId = await ApiCustomerAccount.getCustomerAccountOfLoggedInUser(
          this.props.tokenResponse.accessToken
        );
        if (!customerId) {
          alert("Could not find related Customer Account for this login");
        } else {
          await ApiCustomerAccount.updateCustomerLastLoginDate(
            this.props.tokenResponse.accessToken,
            customerId
          );
        }
      }

      const materials = await ApiLists.getMaterials();
      var modelCategories = await ApiLists.getModelCategories();

      this.setState({
        loading: false,
        appState: {
          groupsOfLoggedInUser,
          customerId,
          customerName: this.state.appState.customerName,
          accessToken: this.props.tokenResponse.accessToken,
          crossfadeIntervalId: 0,
          materials,
          modelCategories,
          pageSize: this.state.appState.pageSize,
        },
      });
      clearInterval(this.state.crossfadeIntervalId);
    }
  }

  onImpersonate = (customerId, customerName) => {
    let appState = { ...this.state.appState };
    appState.customerId = customerId;
    appState.customerName = customerName;
    this.setState({ appState });
  };

  onClearImpersonation = () => {
    let appState = { ...this.state.appState };
    appState.customerId = null;
    appState.customerName = null;
    this.setState({ appState });
    window.location.href = "/";
  };

  onUpdateModelCategories = async () => {
    var modelCategories = await ApiLists.getModelCategories(
      this.props.tokenResponse.accessToken
    );
    let appState = { ...this.state.appState };
    appState.modelCategories = modelCategories;
    await this.setState({ appState });
  };

  render() {
    const publicRender = (
      <>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => (
              <Login
                {...props}
                appState={this.state.appState}
                onSignIn={this.props.onSignIn}
              />
            )}
          />
          <Route
            component={(props) => (
              <PageNotFound {...props} appState={this.state.appState} />
            )}
          />
        </Switch>
      </>
    );

    const authenticatedRender = (
      <>
        <Menu
          appState={this.state.appState}
          account={this.props.account}
          onSignOut={this.props.onSignOut}
          customerName={this.state.appState.customerName}
          onClearImpersonation={this.onClearImpersonation}
        />
        <div className="content">
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Home {...props} appState={this.state.appState} />
              )}
            />
            <Route
              path="/model/addCustomer"
              render={(props) => (
                <ModelAddBase
                  {...props}
                  appState={this.state.appState}
                  addCustomer={true}
                />
              )}
            />
            <Route
              path="/model/addAdmin"
              render={(props) => (
                <ModelAddBase
                  {...props}
                  appState={this.state.appState}
                  addCustomer={false}
                />
              )}
            />
            <Route
              path="/model/list/customer"
              render={(props) => (
                <CustomerModelList {...props} appState={this.state.appState} />
              )}
            />
            <Route
              path="/model/list/generic"
              render={(props) => (
                <GenericModelList {...props} appState={this.state.appState} />
              )}
            />
            <Route
              path="/model/categoryManagement"
              render={(props) => (
                <ModelCategoryManagement
                  {...props}
                  appState={this.state.appState}
                  updateModelCategories={this.onUpdateModelCategories}
                />
              )}
            />
            <Route
              path="/model/detail/:id"
              render={(props) => (
                <ModelDetail {...props} appState={this.state.appState} />
              )}
            />
            <Route
              path="/account/list-live"
              render={(props) => (
                <AccountListLive
                  {...props}
                  appState={this.state.appState}
                  onImpersonate={this.onImpersonate}
                />
              )}
            />
            <Route
              path="/account/list-admin"
              render={(props) => (
                <AccountAdminList {...props} appState={this.state.appState} />
              )}
            />
            <Route
              path="/account/detail-live/:id"
              render={(props) => (
                <AccountLiveDetail
                  {...props}
                  appState={this.state.appState}
                  onImpersonate={this.onImpersonate}
                />
              )}
            />
            <Route
              path="/error/:id/:message"
              render={(props) => (
                <Error {...props} appState={this.state.appState} />
              )}
            />
            <Route
              path="/reports/model"
              exact
              render={(props) => (
                <ReportsModel {...props} appState={this.state.appState} />
              )}
            />
            <Route
              component={(props) => (
                <PageNotFound {...props} appState={this.state.appState} />
              )}
            />
          </Switch>
        </div>
      </>
    );

    const choiceRender = !this.props.account
      ? publicRender
      : authenticatedRender;

    return (
      <Router>
        <LoadingOverlay loading={this.state.loading} />
        {choiceRender}
        {this.props.error && <p className="error">Error: {this.props.error}</p>}
      </Router>
    );
  }
}

export default AuthProvider(App);
