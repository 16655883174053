import React from 'react';

import LoadingOverlay from '../../components/loading-overlay';
import ApiAdmin from '../../api/admin';
import { isUserSuperAdmin } from '../../utils/user';

export default class AccountAdminList extends React.Component {
   //static contextType = TokenContext;

   state = {
      superAdminUsers: [],
      adminUsers: [],
      companyDirectoryUsers: [],
      allGroups: [],
      showAddNewDialog: false,
      name: '',
      email: '',
      isValid: true,
      validationMessage: '',
   };

   async init() {
      this.setState({ loading: true });
      const allGroups = await ApiAdmin.getAllGroups(this.props.appState.accessToken);
      const superAdminUsers = await ApiAdmin.getSuperAdminUsers(this.props.appState.accessToken, allGroups);
      const adminUsersLoaded = await ApiAdmin.getAdminUsers(this.props.appState.accessToken, allGroups);
      const companyDirectoryUsersLoaded = await ApiAdmin.getCompanyDirectoryUsers(this.props.appState.accessToken);

      // Remove admin users that are also superadmins from the list of admins
      let adminUsers = [];
      adminUsersLoaded.forEach((adminUser) => {
         if (!superAdminUsers.some((superAdmin) => superAdmin.userId === adminUser.userId)) {
            adminUsers.push(adminUser);
         }
      });

      // Remove admin users loaded (which includes super admins) from the list of company users
      let companyDirectoryUsers = [];
      companyDirectoryUsersLoaded.forEach((companyDirectoryUser) => {
         if (!adminUsersLoaded.some((admin) => admin.userId === companyDirectoryUser.userId)) {
            companyDirectoryUsers.push(companyDirectoryUser);
         }
      });

      this.setState({ adminUsers, superAdminUsers, companyDirectoryUsers, allGroups, loading: false });
   }

   componentDidMount() {
      if (this.props.appState.accessToken) this.init();
   }

   componentDidUpdate(prevProps, prevState) {
      if (
         (this.props.appState.accessToken && prevProps.appState.accessToken !== this.props.appState.accessToken) ||
         (prevState.name.length > 0 && this.state.name.length === 0)
      ) {
         this.init();
      }
   }

   toggleAdminStatus = async (user, enable) => {
      this.setState({ loading: true });
      const success = await ApiAdmin.toggleAdminUserStatus(
         this.props.appState.accessToken,
         user,
         this.state.allGroups,
         enable
      );
      if (success) {
         this.init();
      }
      this.setState({ loading: false });
   };

   onInputChange = (e) => {
      const { value, name } = e.target;
      this.setState({ [name]: value, changed: true });
   };

   onAddNewUser = async () => {
      if (this.state.name.length < 3 || !this.state.name.includes(' ')) {
         this.setState({
            isValid: false,
            validationMessage: 'Must enter a valid name',
         });
         return;
      } else if (!this.state.email.includes(process.env.REACT_APP_EMAIL_DOMAIN)) {
         this.setState({
            isValid: false,
            validationMessage: `Email must be within the domain of ${process.env.REACT_APP_EMAIL_DOMAIN}`,
         });
         return;
      } else {
         this.setState({ isValid: true });
      }

      this.setState({ loading: true });
      const user = {
         userName: this.state.name,
         mailNickname: this.state.name,
         email: this.state.email,
         accountEnabled: true,
         passwordProfile: {
            forceChangePasswordNextSignIn: true,
            password: 'Password123',
         },
      };
      const newUser = await ApiAdmin.createUser(this.props.appState.accessToken, user);
      if (newUser) {
         const successAddToGroup = await ApiAdmin.assignUserToAdminGroup(
            this.props.appState.accessToken,
            newUser.userId,
            this.state.allGroups
         );
         if (successAddToGroup) {
            this.setState({ showAddNewDialog: false, name: '', email: '' });
         }
      }
      this.setState({ loading: false });
   };

   render() {
      const listUsers = (users, canRemove, canAdd) => {
         if (!users || users.length === 0) return;

         return users.map((user) => {
            // const enableClass = user.accountEnabled ? 'negative' : 'positive';
            return (
               <div className='list-item no-link' key={user.email}>
                  <p className={!canAdd && !canRemove ? 'col-6 text-center' : 'col-4 text-center'}>{user.userName}</p>
                  <p className={!canAdd && !canRemove ? 'col-6 text-center' : 'col-4 text-center'}>{user.email}</p>
                  {canRemove && (
                     <div className='col-4 text-center'>
                        <p className='link-button' onClick={() => this.toggleAdminStatus(user, false)}>
                           Remove from Admins
                        </p>
                     </div>
                  )}
                  {canAdd && (
                     <div className='col-4 text-center'>
                        <p className='link-button' onClick={() => this.toggleAdminStatus(user, true)}>
                           Add to Admins
                        </p>
                     </div>
                  )}
               </div>
            );
         });
      };

      const listHeader = (
         <div className='list-header'>
            <p className='col-4 text-center text-white'>Name</p>
            <p className='col-4 text-center text-white'>Email</p>
            <p className='col-4 text-center text-white'>Actions</p>
         </div>
      );

      return (
          isUserSuperAdmin(this.props.appState) ? (
            <div className='gal3d-body font-stack-s0 enable-scroll-styling'>
                <LoadingOverlay loading={this.state.loading}/>
                <div className='container-fluid'>
                    <div className='gallery-detail-contents mt-0 mt-md-5'>
                        <div className='row'>
                            <div className='col-12 detail-contents p-4'>
                                <div className='row pb-4 d-flex flex-row-reverse flex-md-row'>
                                    <div className='col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center'>
                                    <h1 className='text-center text-md-left'><strong>Admins</strong></h1>
                                    </div>
                                </div>

                                <h2>Super Admins</h2>
                                <div className='list-header'>
                                    <p className='col-6 text-center text-white'>Name</p>
                                    <p className='col-6 text-center text-white'>Email</p>
                                </div>
                                {listUsers(this.state.superAdminUsers, false, false)}

                                <h2>Admins</h2>
                                {listHeader}
                                {listUsers(this.state.adminUsers, true, false)}

                                <h2>All Directory Users</h2>
                                {listHeader}
                                {listUsers(this.state.companyDirectoryUsers, false, true)}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )
      );
   }
}
