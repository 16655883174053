import Api from './api';

export default class ApiReporting extends Api {
   // Reporting
   static async accounts(accessToken, reportingFilter) {
      const data = await this.post(`/api/Reporting/accounts`, accessToken, reportingFilter, true);
      return data;
   }

   static async models(accessToken, reportingFilter) {
      const data = await this.post(`/api/Reporting/models`, accessToken, reportingFilter, true);
      return data;
   }

   static async orders(accessToken, reportingFilter) {
      const data = await this.post(`/api/Reporting/orders`, accessToken, reportingFilter, true);
      return data;
   }
}
