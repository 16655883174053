import React from 'react';

export default class Login extends React.Component {

   render() {
      return (
        <>
            <div className='login-background-image'></div>
            <div className='login-mask'>
                <div className='dialog-box'>
                    <img src='../images/Cooksongold3D_Logo.svg' alt='Cooksongold 3D Logo' className='logo'/>
                    <div className='title'>
                        Welcome to the Cooksongold 3D Admin Portal         
                    </div>
                    <div className='center-cont'>
                        <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.props.onSignIn}>Sign In</button>
                    </div>
                    <br/>
                </div>
            </div>
        </>
      );
   }
}
