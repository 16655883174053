import React from "react";
import { Link } from "react-router-dom";
import { GetShortDateString } from "../../utils/date";
import imageNotAvailable from "../../images/image-not-available.png";
import { isUserAdmin } from "../../utils/user";
export default class ModelThumbnail extends React.Component {
  render() {
    const m = this.props.model;
    let url = "";
    if (m) {
      url = `/model/detail/${m.modelId}`;
    }

    const imageSource =
      m && m.thumbnailPath
        ? m.thumbnailPath + `&lastUpdated=${m.lastUpdatedDate}`
        : imageNotAvailable;

    const thumbnailCard = (
      <div className="card" data-isNew={m.isNew} data-istrending={m.isTopN}>
        <div className="row no-gutters">
          <div className="col-4 pl-4 mt-auto mb-auto">
            <img
              src={imageSource}
              className="card-img-top p-1"
              alt="model thumbnail"
            />
          </div>
          <div className="col-8">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {m.reference && (
                  <strong>
                    {m.reference.length > 20
                      ? m.reference.slice(0, 20) + "..."
                      : m.reference}
                  </strong>
                )}
              </h2>
              <p className="card-text">
                <strong>Uploaded: </strong> {GetShortDateString(m.createdDate)}{" "}
                <br />
                {isUserAdmin(this.props.appState) && m.erpCustomerReference && (
                  <span>
                    <strong>Customer: </strong>{" "}
                    {m.erpCustomerReference &&
                      (m.erpCustomerReference.length > 15
                        ? m.erpCustomerReference.slice(0, 15) + "..."
                        : m.erpCustomerReference)}
                  </span>
                )}
              </p>

              {m.cadAnalysisResult && (
                <p className="card-reported">
                  <strong>Volume: </strong>
                  {m.cadAnalysisResult.volume}mm<sup> 3</sup> <br />
                  <strong>Hollow: </strong>
                  {m.cadAnalysisResult.hollowedThickness >= 0 ? "Yes" : "No"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="col-md-12 col-lg-3 col-sm-3 pb-4">
        <Link to={url} className="text-decoration-none">
          {thumbnailCard}
        </Link>
      </div>
    );
  }
}
