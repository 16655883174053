import download from "downloadjs";

export default class Api {
  static apiHost = process.env.REACT_APP_API_HOST;
  static uiHost = process.env.REACT_APP_UI_HOST;

  static handleError = (error) => {
    var errorMessage = error.Message ?? error.message;
    var errorStatusCode = error.StatusCode ?? error.statusCode;

    const uri = encodeURI(
      `${this.uiHost}/#/error/${errorStatusCode}/${errorMessage}`
    );
    window.location.assign(uri);
  };

  // Base fetch methods
  static async get(url, accessToken) {
    try {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
      const result = await fetch(`${this.apiHost + url}`, {
        headers,
        method: "get",
      });
      const data = await result.json();
      if (!result.ok) {
        this.handleError(data);
      }
      return data;
    } catch (error) {
      //this.handleError(error);
    }
  }

  static async genericPost(url, accessToken, body) {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
    body = JSON.stringify(body);
    const result = await fetch(`${this.apiHost + url}`, {
      headers,
      method: "post",
      body,
    });
    return result;
  }

  static async postWithDownload(url, accessToken, body, filename) {
    try {
      const result = await this.genericPost(url, accessToken, body);

      if (!result.ok) {
        const error = await result.json();
        if (!accessToken) return error;
        this.handleError(error);
      }

      var blob = await result.blob();
      download(blob, filename, blob.type);
    } catch (error) {
      //this.handleError(error);
    }
  }

  static async post(url, accessToken, body, returnsData = false) {
    try {
      const result = await this.genericPost(url, accessToken, body);

      if (!result.ok) {
        const error = await result.json();
        if (!accessToken) return error;
        this.handleError(error);
      }
      if (returnsData) return await result.json();
      return result.ok;
    } catch (error) {
      //this.handleError(error);
    }
  }

  static async put(url, accessToken, body) {
    try {
      body = JSON.stringify(body);
      const result = await fetch(`${this.apiHost + url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "put",
        body,
      });
      if (!result.ok) {
        const error = await result.json();
        this.handleError(error);
      }
      return result.ok;
    } catch (error) {
      //this.handleError(error);
    }
  }

  static async patch(url, accessToken) {
    try {
      const result = await fetch(`${this.apiHost + url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "patch",
      });
      if (!result.ok) {
        const error = await result.json();
        this.handleError(error);
      }
      return result.ok;
    } catch (error) {
      //this.handleError(error);
    }
  }

  static async delete(url, accessToken, body) {
    try {
      body = JSON.stringify(body);
      const result = await fetch(`${this.apiHost + url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "delete",
        body,
      });
      if (!result.ok) {
        const error = await result.json();
        this.handleError(error);
      }
      return result.ok;
    } catch (error) {
      //alert(error);
    }
  }

  static async upload(url, accessToken, formData, returnsData = false) {
    try {
      //file = JSON.stringify(file);
      const result = await fetch(`${this.apiHost + url}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: formData,
      });
      if (!result.ok) {
        const error = await result.json();
        this.handleError(error);
      }
      if (returnsData) return await result.json();
      return result.ok;
    } catch (error) {
      //alert(error);
    }
  }
}
