import React from 'react';
import { Link } from 'react-router-dom';
import { GetShortDateString } from '../../utils/date';

export default class AccountListItem extends React.Component {
   render() {
      let listItemRender;

      const filter = this.props.filter['CustomerState'];
      if (filter === 'Live' || filter === 'Deactivated') {
         const url = `/account/detail-live/${this.props.account.customerId}`;
         listItemRender = (
            <Link to={url} className='list-item border'>
               <p className={`col-${this.props.cols[0].width} text-center`}>{this.props.account.erpCustomerReference}</p>
               <p className={`col-${this.props.cols[1].width} text-center`}>{this.props.account.erpCustomerName ?? 'N/A'}</p>
               <p className={`col-${this.props.cols[2].width} text-center`}>
                  {GetShortDateString(this.props.account.createdDate)}
               </p>
               <p className={`col-${this.props.cols[3].width} text-center`}>
                  {this.props.account.lastLoginDate ? GetShortDateString(this.props.account.lastLoginDate) : 'Never'}
               </p>
            </Link>
         );
      }

      return listItemRender;
   }
}
