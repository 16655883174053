import React from "react";

import LoadingOverlay from "../../components/loading-overlay";

import ApiModel from "../../api/model";

import { Draggable } from "react-drag-reorder";

export default class ModelCategoryManagement extends React.Component {
  state = {
    loading: false,
    modelCategories: null,
    newCategoryName: "",
    changed: false,
    showDeleteDialog: false,
    showDragDialog: false,
    modelCategoryToDeleteId: null,
    modelCategoryToDeleteName: null,
    displayIdUpdates: null,
  };

  init = async () => {
    let modelCategories = [...this.props.appState.modelCategories];
    modelCategories.shift();
    await this.setState({ modelCategories });
  };

  componentDidMount() {
    if (this.props.appState.accessToken) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.appState.accessToken &&
        prevProps.appState.accessToken !== this.props.appState.accessToken) ||
      (this.props.appState.modelCategories &&
        prevProps.appState.modelCategories !==
          this.props.appState.modelCategories)
    ) {
      this.init();
    }
  }

  toggleArchived = (e) => {
    this.setState({ showArchived: e.target.checked });
  };

  onNewCategoryNameChange = async (e) => {
    await this.setState({ newCategoryName: e.target.value, changed: true });
  };

  onAddNewCategory = async () => {
    if (this.state.changed && this.state.newCategoryName.trim() !== "") {
      this.setState({ loading: true });
      await ApiModel.createModelCategory(
        this.props.appState.accessToken,
        this.state.newCategoryName
      );
      this.setState({ loading: false, changed: false, newCategoryName: "" });
      await this.props.updateModelCategories();
    }
  };

  onDeleteModelCategoryConfirm = async () => {
    this.setState({ loading: true });
    await ApiModel.deleteModelCategory(
      this.props.appState.accessToken,
      this.state.modelCategoryToDeleteId
    );
    await this.props.updateModelCategories();
    this.setState({ loading: false, showDeleteDialog: false });
  };

  onDragModelCategoryConfirm = async () => {
    await ApiModel.updateModelCategory(
      this.props.appState.accessToken,
      this.state.displayIdUpdates
    );
    await this.props.updateModelCategories();
    this.setState({ loading: false, showDragDialog: false });
  };

  getChangedPos = async (currentPos, newPos) => {
    var items = this.state.modelCategories;

    // Need to save these ojects so that they can be inserted, once the new slot becomes available.
    var currentPosObj = this.state.modelCategories[currentPos];
    var newPosObj = this.state.modelCategories[newPos];

    var displayIdUpdates = [];

    if (currentPos !== newPos) {
      if (currentPos > newPos) {
        /* (currentPos >  newPos)  - Moving UP. 
           Starting from the New position, move each piece DOWN one.
           This will a Spare SLOT at the TOP.
           Place the Current Saved Object in the spare slot created by the moves.
        */
        for (let i = 0; i < items.length; i++) {
          if (i >= newPos && i < currentPos) {
            displayIdUpdates.push({
              modelCategoryId: items[i].modelCategoryId,
              displayId: items[i].displayIndex,
              newId: items[i + 1].displayIndex,
            });
          }
        }
        displayIdUpdates.push({
          modelCategoryId: currentPosObj.modelCategoryId,
          displayId: newPosObj.displayIndex,
          newId: newPosObj.displayIndex,
        });
      } else {
        /* (currentPos <  newPos) - Moving DOWN. 
           Starting from the current position, move each piece UP one.
           This will a Spare SLOT at the target Position.
           Place the Current Saved Object in the spare slot created by the moves.
        */
        for (let i = 0; i < items.length; i++) {
          if (i > currentPos && i <= newPos) {
            displayIdUpdates.push({
              modelCategoryId: items[i].modelCategoryId,
              displayId: items[i].displayIndex,
              newId: items[i - 1].displayIndex,
            });
          }
          if (i === newPos) {
            displayIdUpdates.push({
              modelCategoryId: currentPosObj.modelCategoryId,
              displayId: newPosObj.displayIndex,
              newId: newPosObj.displayIndex,
            });
          }
        }
      }
    }

    if (displayIdUpdates.length > 0) {
      this.setState({ displayIdUpdates, showDragDialog: true });
    }
  };

  render() {
    const listRender = this.state.modelCategories && (
      <div className="pre-scrollable col-6 mx-auto">
        <Draggable onPosChange={this.getChangedPos}>
          {this.state.modelCategories
            .sort((a, b) => a.displayIndex - b.displayIndex)
            .map((modelCategory) => {
              return (
                <div
                  className="list-item no-link justify-content-between"
                  key={"div" + modelCategory.id}
                >
                  <span
                    className="col-3 text-left"
                    key={"title" + modelCategory.id}
                  >
                    {modelCategory.description}
                  </span>
                  <span className="col-2 text-left">
                    <button
                      className="btn btn-primary cng3d-general-button caution"
                      onClick={async () => {
                        await this.setState({
                          showDeleteDialog: true,
                          modelCategoryToDeleteId: modelCategory.id,
                          modelCategoryToDeleteName: modelCategory.description,
                        });
                      }}
                    >
                      Delete
                    </button>
                  </span>
                </div>
              );
            })}
        </Draggable>
      </div>
    );

    const addNewCategoryRender = (
      <div className="row d-flex align-items-center">
        <p className="col-6 text-right">New Category Name</p>
        <div className="col-6 text-left">
          <input
            name="category-name"
            className="data"
            value={this.state.newCategoryName}
            onChange={this.onNewCategoryNameChange}
            maxLength={20}
          />
          <button
            className={
              this.state.changed && this.state.newCategoryName.trim() !== ""
                ? "btn btn-primary cng3d-general-button"
                : "btn btn-primary cng3d-general-button disabled"
            }
            onClick={this.onAddNewCategory}
          >
            Add
          </button>
        </div>
      </div>
    );

    const deleteDialog = this.state.showDeleteDialog && (
      <div className={"dialog-mask"}>
        <div className={"dialog-box"}>
          <div className="title">
            Delete Category: {this.state.modelCategoryToDeleteName}
          </div>
          <p>Are you sure you want to delete this category?</p>
          <div className="center-cont">
            <div className="left-cont">
              <button
                className="btn btn-primary cng3d-general-button"
                onClick={() => {
                  this.setState({
                    showDeleteDialog: false,
                    modelCategoryToDeleteId: null,
                    modelCategoryToDeleteName: null,
                  });
                }}
              >
                Cancel
              </button>
            </div>
            <div className="right-cont">
              <button
                className="btn btn-primary cng3d-general-button caution"
                onClick={this.onDeleteModelCategoryConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    const dragDialog = this.state.showDragDialog && (
      <div className={"dialog-mask"}>
        <div className={"dialog-box"}>
          <div className="title">Move Category ?</div>
          <p>Are you sure you want to move this category?</p>
          <div className="center-cont">
            <div className="left-cont">
              <button
                className="btn btn-primary cng3d-general-button"
                onClick={async () => {
                  this.setState({
                    showDragDialog: false,
                  });
                  // The drag component does not refresh the list on ComponentDidUpdate.
                  // use the Hammer.
                  window.location.reload();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="right-cont">
              <button
                className="btn btn-primary cng3d-general-button caution"
                onClick={this.onDragModelCategoryConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container-fluid">
        <div className="row">
          <LoadingOverlay loading={this.state.loading} />
          <div className="portal-container d-flex align-items-center">
            <div className="col-12 portal-contents p-4">
              <div className="col-12">
                <h3>
                  <strong>Category Management</strong>
                </h3>
                <div
                  className="text-center"
                  key={this.state.modelCategories?.length}
                >
                  <p>
                    <strong>Add New Category</strong>
                  </p>
                  {addNewCategoryRender}
                  <p>
                    <strong>Order List or Delete Existing Categories</strong>
                  </p>
                  <div className="flex-container">
                    <div className="column">
                      <div className="font-stack-s5">{listRender}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {deleteDialog}
            {dragDialog}
          </div>
        </div>
      </div>
    );
  }
}
