import React from "react";
import { Prompt } from "react-router-dom";
import LoadingOverlay from "../loading-overlay";
import ModelAdd1Upload from "./add-1-upload";
import ModelAdd2Analyse from "./add-2-analyse";
import ModelAdd3Submit from "./add-3-submit";

import ApiFile from "../../api/file";
import ApiModel from "../../api/model";

export default class ModelAddBase extends React.Component {
  state = {
    pageNumber: 1,
    fileId: null,
    fileUri: null,
    thumbnailUri: null,
    displayNext: false,
    displayPrev: false,
    loading: false,
    uploading: false,
    models: [],
    analysing: false,
    results: null,
    reference: "",
    description: "",
    userNote: "",
    isValid: false,
    changed: false,
    analysisId: null,
    modelCategoryId: 0,
    submitted: false,
    modelId: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.reference !== this.state.reference ||
      prevState.description !== this.state.description ||
      prevState.analysing !== this.state.analysing ||
      prevState.modelCategoryId !== this.state.modelCategoryId
    ) {
      const isValid =
        this.state.reference.length > 0 &&
        !this.state.analysing &&
        this.state.pageNumber > 1 &&
        this.state.results.printable;
      this.setState({ isValid });
    }
  }

  onChangeLoadingState = (loading) => {
    this.setState({ loading });
  };

  onChangeUploadingState = (uploading) => {
    this.setState({ uploading });
  };

  onChangeAnalysingState = (analysing, results) => {
    this.setState({ analysing, results, changed: true });
  };

  onChangeAnalysisId = (analysisId) => {
    this.setState({ analysisId });
  };

  onChangeDisplayPrev = (isDisplaying) => {
    this.setState({ displayPrev: isDisplaying });
  };

  onChangeDisplayNext = (isDisplaying) => {
    this.setState({ displayNext: isDisplaying });
  };

  onInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value, changed: true });
  };

  onNext = (model) => {
    const displayPrev =
      this.state.pageNumber + 1 > 1 && this.state.pageNumber + 1 < 3;
    const displayNext = this.state.pageNumber + 1 < 2;
    if (model && model.modelId > 0) {
      const models = [...this.state.models];
      models.push(model);
      this.setState({ models });
    }
    this.setState({
      pageNumber: this.state.pageNumber + 1,
      displayPrev,
      displayNext,
    });
  };

  onNewFileId = async (fileId) => {
    this.setState({ loading: true });
    const fileInfo = await ApiFile.getCustomerFileInfo(
      this.props.appState.accessToken,
      fileId
    );
    this.setState({
      loading: false,
      fileId,
      fileUri: fileInfo.fileAccessPath,
      thumbnailUri: fileInfo.thumbnailAccessPath,
      analysisId: fileInfo.cadAnalysisResults,
    });
  };

  onChangeFileUri = async (newFileUri) => {
    this.setState({ fileUri: newFileUri });
  };

  addNewModel = () => {
    this.setState({
      pageNumber: 1,
      fileId: null,
      fileUri: null,
      thumbnailUri: null,
      displayPrev: false,
      displayNext: false,
      description: "",
      reference: "",
      userNote: "",
      analysing: false,
      results: null,
      modelCategoryId: 0,
      submitted: false,
      modelId: null,
    });
  };

  setDefaultReference = (reference) => {
    reference = reference.length > 20 ? reference.slice(0, 20) : reference;
    this.setState({ reference });
  };

  onSubmit = async () => {
    if (this.state.isValid) {
      this.setState({ loading: true });
      const modelSubmit = {
        customerFileId: this.state.fileId,
        modelType: 2, // 1 = Mould, 2 = CAD
        description: this.state.description,
        reference: this.state.reference,
        userNote: this.state.userNote,
        modelCategoryId: parseInt(this.state.modelCategoryId),
      };

      if (this.props.addCustomer) {
        modelSubmit.customerId = this.props.appState.customerId;
      }

      const modelOut = await ApiModel.create(
        this.props.appState.accessToken,
        modelSubmit
      );
      if (modelOut) {
        this.setState({
          loading: false,
          isValid: false,
          changed: false,
          submitted: true,
          modelId: modelOut.modelId,
        });
        //this.onNext(modelOut);
      }
    }
  };

  onChangeModelCategory = (e) => {
    this.changeModelCategory(e.target.value);
  };

  changeModelCategory = async (modelCategoryId) => {
    this.setState({ loading: true });
    await this.setState({ modelCategoryId });
    this.setState({ loading: false });
  };

  render() {
    let childContentRender = null;
    switch (this.state.pageNumber) {
      case 1: {
        childContentRender = (
          <ModelAdd1Upload
            appState={this.props.appState}
            fileUri={this.state.fileUri}
            changeLoadingState={this.onChangeLoadingState}
            changeUploadingState={this.onChangeUploadingState}
            changeAnalysingState={this.onChangeAnalysingState}
            onNewFileId={this.onNewFileId}
            changeDisplayNext={this.onChangeDisplayNext}
            setDefaultReference={this.setDefaultReference}
            changeAnalysisId={this.onChangeAnalysisId}
            changePage={this.onNext}
            addCustomer={this.props.addCustomer}
          />
        );
        break;
      }
      case 2:
        childContentRender = (
          <ModelAdd2Analyse
            appState={this.props.appState}
            fileUri={this.state.fileUri}
            fileId={this.state.fileId}
            analysing={this.state.analysing}
            results={this.state.results}
            reference={this.state.reference}
            description={this.state.description}
            userNote={this.state.userNote}
            analysisId={this.state.analysisId}
            changeLoadingState={this.onChangeLoadingState}
            changeAnalysingState={this.onChangeAnalysingState}
            changeAnalysisId={this.onChangeAnalysisId}
            changeDisplayPrev={this.onChangeDisplayPrev}
            changeDisplayNext={this.onChangeDisplayNext}
            changeFileUri={this.onChangeFileUri}
            inputChange={this.onInputChange}
            addCustomer={this.props.addCustomer}
            modelCategoryId={this.state.modelCategoryId}
            changeModelCategory={this.onChangeModelCategory}
            changePage={this.onNext}
          />
        );
        break;
      case 3:
        childContentRender = (
          <ModelAdd3Submit
            appState={this.props.appState}
            fileUri={this.state.fileUri}
            fileId={this.state.fileId}
            analysing={this.state.analysing}
            results={this.state.results}
            reference={this.state.reference}
            description={this.state.description}
            userNote={this.state.userNote}
            analysisId={this.state.analysisId}
            changeLoadingState={this.onChangeLoadingState}
            changeAnalysingState={this.onChangeAnalysingState}
            changeDisplayPrev={this.onChangeDisplayPrev}
            changeDisplayNext={this.onChangeDisplayNext}
            changeFileUri={this.onChangeFileUri}
            inputChange={this.onInputChange}
            addCustomer={this.props.addCustomer}
            modelCategoryId={this.state.modelCategoryId}
            changeModelCategory={this.onChangeModelCategory}
            submit={this.onSubmit}
            submitted={this.state.submitted}
            addNewModel={this.addNewModel}
            modelId={this.state.modelId}
          />
        );
        break;
      case 4:
        break;
      case 5:
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Prompt
          when={
            this.state.analysing || this.state.changed || this.state.changed
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <LoadingOverlay
          loading={this.state.loading}
          uploading={this.state.uploading}
          displayText={
            this.state.pageNumber > 1 ? "Loading..." : "Uploading..."
          }
        />

        <div className="">{childContentRender}</div>
      </React.Fragment>
    );
  }
}
