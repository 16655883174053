import React from "react";

export function GetMultilineString(input) {
  if (!input) return "";
  return input.split("\n").map(function (line, i, arr) {
    return (
      <span key={i}>
        {line}
        {arr.length - 1 === i ? null : <br />}
      </span>
    );
  });
}

export function GetFileExtension(input) {
  var re = /(?:\.([^.]+))?$/;
  return re.exec(input)[1];
}
