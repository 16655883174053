import React from 'react';
import { GetShortDateString } from '../utils/date';

export default class NoteListItem extends React.Component {
   render() {
      return (
         <div className='list-item'>
            <p className='col-3 text-center'>{GetShortDateString(this.props.createdDate)}</p>
            <p className='col-3 text-center'>{this.props.username}</p>
            <p className='col-6 text-center'>{this.props.text}</p>            
         </div>
      );
   }
}
