import { UserAgentApplication } from 'msal';

export const requiresInteraction = (errorMessage) => {
   if (!errorMessage || !errorMessage.length) {
      return false;
   }

   return (
      errorMessage.indexOf('consent_required') > -1 ||
      errorMessage.indexOf('interaction_required') > -1 ||
      errorMessage.indexOf('login_required') > -1
   );
};

export const fetchMsGraph = async (url, accessToken) => {
   const response = await fetch(url, {
      headers: {
         Authorization: `Bearer ${accessToken}`,
      },
   });

   return response.json();
};

export const isIE = () => {
   const ua = window.navigator.userAgent;
   const msie = ua.indexOf('MSIE ') > -1;
   const msie11 = ua.indexOf('Trident/') > -1;

   // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
   // const isEdge = ua.indexOf("Edge/") > -1;

   return msie || msie11;
};

export const GRAPH_SCOPES = {

    US: process.env.REACT_APP_AUTH_SCOPE_US,
    //OPENID: "openid",
    //PROFILE: "profile",
    //USER_READ: "User.Read",
    //MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: `${process.env.REACT_APP_API_HOST}/api/users`,
    MAIL: "https://graph.microsoft.com/v1.0/me/messages"
};

export const GRAPH_REQUESTS = {
   LOGIN: {
      scopes: [GRAPH_SCOPES.US],
   },
   EMAIL: {
      scopes: [GRAPH_SCOPES.MAIL_READ],
   },
};

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        validateAuthority: true,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 0,
        logger: {
            error: console.error,
            errorPii: console.error,
            info: console.log,
            infoPii: console.log,
            verbose: console.log,
            verbosePii: console.log,
            warning: console.warn,
            warningPii: console.warn
        }
    }
});
