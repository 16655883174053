import React from "react";
import Model3dViewer from "../3d-viewer/model-3d-viewer";
import { GetShortDateString } from "../../utils/date";
import { GetMultilineString } from "../../utils/string";
import { isUserAdmin } from "../../utils/user";
import ApiModel from "../../api/model";
import LoadingOverlay from "../loading-overlay";
import ApiFile from "../../api/file";

export default class ModelDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: {},
      isNew: false,
      isTopN: false,
      description: "",
      userNote: "",
      editReference: false,
      editDescription: false,
      editUserNote: false,
      selectedFile: null,
      uploaded: false,
      results: null,
      adminNotesString: "",
      newAdminNote: "",
      fileUri: "",
      selectedModelCategory: null,
      showDeleteDialog: false,
      deleted: false,
      modelViewerKey: false,
    };

    this.replaceCadModel = React.createRef();
    this.replaceThumbnail = React.createRef();
  }

  init() {
    this.getModel(this.props.match.params.id);
  }

  componentDidMount() {
    if (this.props.appState.accessToken) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.appState.accessToken &&
      prevProps.appState.accessToken !== this.props.appState.accessToken
    ) {
      this.init();
    }
  }

  async getModel(modelId) {
    this.setState({ loading: true });
    const model = await ApiModel.getModel(
      this.props.appState.accessToken,
      modelId
    );
    let results = "";
    if (model.modelType === "CAD") {
      const file = await ApiFile.getCustomerFileInfo(
        this.props.appState.accessToken,
        model.customerFileId
      );
      results = file.cadAnalysisResults;
    }
    await this.formatModelAdminNotes(model.adminNotes);

    this.setState({
      model, // Orignal Model from back end

      // Fields to Update Model
      isNew: model.isNew,
      isTopN: model.isTopN,
      reference: model.reference,
      description: model.description,
      userNote: model.userNote,
      loading: false,
      results,
      fileUri: model.filePath,
      selectedModelCategory: model.modelCategoryId,
    });

    if (model.customerId === null && model.modelCategoryId === null) {
      await this.setState({ selectedModelCategory: 0 });
      this.updateModel("modelCategory");
    }
  }

  formatModelAdminNotes = async (adminNotes) => {
    var noteString = this.state.adminNotesString;
    adminNotes
      .slice(0)
      .reverse()
      .forEach((element) => {
        noteString +=
          "\n" +
          GetShortDateString(element.createdDate) +
          " - " +
          element.username +
          ":\n" +
          element.noteText +
          "\n";
      });
    // remove trailing newline
    noteString = noteString.replace(/^\s+|\s+$/g, "");
    this.setState({ adminNotesString: noteString });
  };

  onEditReference = () => {
    this.setState({
      editReference: true,
      reference: this.state.model.reference,
    });
  };

  onEditDescription = () => {
    this.setState({
      editDescription: true,
      description: this.state.model.description,
    });
  };

  onEditUserNote = () => {
    this.setState({ editUserNote: true, userNote: this.state.model.userNote });
  };

  onEditAdminNote = () => {
    this.setState({ editAdminNote: true });
  };

  onInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value, changed: true });
  };

  onModelCategoryChange = (e) => {
    this.changeModelCategory(e.target.value);
  };

  changeModelCategory = async (modelCategoryId) => {
    this.setState({ loading: true });
    let model = { ...this.state.model };
    model.modelCategoryId = modelCategoryId;
    await this.setState({
      selectedModelCategory: modelCategoryId,
      loading: false,
    });
    this.updateModel("modelCategory");
  };

  updateModel = async (field) => {
    const model = { ...this.state.model };

    switch (field) {
      case "isNew": {
        model.isNew = this.state.isNew;
        break;
      }
      case "isTopN": {
        model.isTopN = this.state.isTopN;
        break;
      }
      case "reference": {
        model.reference = this.state.reference;
        this.setState({ editReference: false });
        break;
      }
      case "description": {
        model.description = this.state.description;
        this.setState({ editDescription: false });
        break;
      }
      case "userNote": {
        model.userNote = this.state.userNote;
        this.setState({ editUserNote: false });
        break;
      }
      case "adminNotes": {
        if (this.state.newAdminNote !== "") {
          await ApiModel.addAdminNote(
            this.props.appState.accessToken,
            model.modelId,
            this.state.newAdminNote
          );
        }
        this.setState({
          editAdminNote: false,
          newAdminNote: "",
          adminNotesString: "",
        });
        this.getModel(this.state.model.modelId);
        break;
      }
      case "modelCategory": {
        model.modelCategoryId = parseInt(this.state.selectedModelCategory);
        break;
      }
      default:
        break;
    }

    this.setState({ loading: true });
    await ApiModel.updateModel(this.props.appState.accessToken, model);
    this.setState({ loading: false, model });
  };

  // On cad file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On image upload (click the upload button)
  onImageFileUpload = async () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("filePayload", this.state.selectedFile);
    const success = await ApiFile.uploadImage(
      this.props.appState.accessToken,
      this.state.model.modelId,
      formData
    );
    if (success) {
      this.setState({ selectedFile: null });
      this.getModel(this.state.model.modelId);
    }
    this.setState({ loading: false });
  };

  // On CAD upload (click the upload button)
  onCadFileUpload = async () => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("filePayload", this.state.selectedFile);
    const success = await ApiFile.replaceCadModel(
      this.props.appState.accessToken,
      this.state.model.modelId,
      formData
    );
    if (success) {
      await ApiModel.updateModel(this.props.appState.accessToken, {
        modelId: this.state.model.modelId,
      });
      this.setState({ selectedFile: null });
      await this.getModel(this.state.model.modelId);
    }
    this.setState({
      loading: false,
      adminNotesString: "",
      modelViewerKey: !this.state.modelViewerKey,
    });
    await this.formatModelAdminNotes(this.state.model.adminNotes);
  };

  onThumbnailFileUpload = async (e) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("filePayload", e.target.files[0]);
    const success = await ApiFile.uploadCadImage(
      this.props.appState.accessToken,
      this.state.model.customerFileId,
      formData
    );

    if (success) {
      await ApiModel.updateModel(this.props.appState.accessToken, {
        modelId: this.state.model.modelId,
      });
      this.setState({ selectedFile: null });
      await this.getModel(this.state.model.modelId);
    }
    this.setState({
      loading: false,
      adminNotesString: "",
      modelViewerKey: !this.state.modelViewerKey,
    });
    await this.formatModelAdminNotes(this.state.model.adminNotes);
  };

  onDownloadCadFileClick = () => {
    window.location.href = this.state.model.filePath;
  };

  onDownloadOriginalCadFileClick = () => {
    if (this.state.model.originalUploadedFilePath)
      window.location.href = this.state.model.originalUploadedFilePath;
  };

  onDeleteModel = () => {
    this.setState({ showDeleteDialog: true });
  };

  onDeleteModelConfirm = async () => {
    this.setState({ loading: true, showDeleteDialog: false });
    const success = ApiModel.deleteModel(
      this.props.appState.accessToken,
      this.state.model.modelId
    );
    if (success) {
      this.setState({ loading: false, deleted: true });
    }
  };

  render() {
    let m = this.state.model;

    const cadUploadAndDataRender = this.state.selectedFile && (
      <div className="col-12 text-center">
        <h4>File Name: {this.state.selectedFile.name}</h4>
        <h4>
          Last Modified:{" "}
          {GetShortDateString(this.state.selectedFile.lastModifiedDate)}
        </h4>
        <button
          className="btn btn-primary cng3d-general-button highlighted"
          onClick={this.onCadFileUpload}
        >
          Upload Now!
        </button>
      </div>
    );

    const referenceRender = m && (
      <div className="row d-flex align-items-center">
        <p className="col-5 text-right">
          <strong>Model Reference:</strong>
        </p>
        {this.state.editReference ? (
          <div className="col-7">
            <p>
              <input
                name="reference"
                value={this.state.reference}
                onChange={this.onInputChange}
                maxLength={50}
              />
            </p>
            <button
              className="btn btn-primary cng3d-general-button highlighted"
              onClick={() => this.updateModel("reference")}
            >
              Save
            </button>
          </div>
        ) : (
          <p className="col-7">
            {this.state.reference} &nbsp;
            {m && !this.state.deleted && (
              <button
                className="btn btn-primary cng3d-general-button"
                onClick={this.onEditReference}
              >
                Edit
              </button>
            )}
          </p>
        )}
      </div>
    );

    const descriptionRender = m && m.customerId === null && (
      <div className="row d-flex align-items-start">
        <p className="col-5 text-right">
          <strong>Description:</strong>
        </p>
        {this.state.editUserNote ? (
          <div className="col-7">
            <p>
              <textarea
                name="userNote"
                className="enable-scroll-styling"
                value={this.state.userNote}
                onChange={this.onInputChange}
              />
            </p>
            <button
              className="btn btn-primary cng3d-general-button highlighted"
              onClick={() => this.updateModel("userNote")}
            >
              Save
            </button>
          </div>
        ) : (
          <p className="col-7">
            {GetMultilineString(this.state.userNote)} &nbsp;
            {m && !this.state.deleted && (
              <button
                className="btn btn-primary cng3d-general-button"
                onClick={this.onEditUserNote}
              >
                Edit
              </button>
            )}
          </p>
        )}
      </div>
    );

    const adminNoteRender = m && (
      <>
        <p className="col-5 text-right">
          <strong>Admin Notes:</strong>
        </p>
        {this.state.editAdminNote ? (
          <div className="col-7">
            <p>
              <textarea
                name="newAdminNote"
                value={this.state.newAdminNote}
                onChange={this.onInputChange}
                maxLength={512}
              />
            </p>
            <button
              className="btn btn-primary cng3d-general-button"
              onClick={() => this.updateModel("adminNotes")}
            >
              Save
            </button>
          </div>
        ) : (
          <div className="col-7">
            {this.state.adminNotesString.length > 0 && (
              <p>{GetMultilineString(this.state.adminNotesString)}</p>
            )}
            {isUserAdmin(this.props.appState) && !this.state.deleted && (
              <button
                className="btn btn-primary cng3d-general-button"
                onClick={this.onEditAdminNote}
              >
                Add New
              </button>
            )}
          </div>
        )}
      </>
    );

    const analysisResultsRender = m && (
      <>
        {m.cadAnalysisResult && (
          <>
            <div className="row d-flex align-items-center">
              <p className="col-5 text-right">
                <strong>Repair Needed:</strong>
              </p>
              <p className="col-7">
                {m.cadAnalysisResult.repairNeeded ||
                m.cadAnalysisResult.startRepair
                  ? "Yes"
                  : "No"}
              </p>
            </div>
            {m.cadAnalysisResult.repairNeeded ||
              (m.cadAnalysisResult.startRepair && (
                <div className="row d-flex align-items-center">
                  <p className="col-5 text-right">
                    <strong>Repair Successful:</strong>
                  </p>
                  <p className="col-7">
                    {m.cadAnalysisResult.repairSuccess ? "Yes" : "No"}
                  </p>
                </div>
              ))}
            <div className="row d-flex align-items-center">
              <p className="col-5 text-right">
                <strong>Hollow:</strong>
              </p>
              <p className="col-7">
                {m.cadAnalysisResult.hollowedThickness > 0 ? "Yes" : "No"}
              </p>
            </div>
            {m.cadAnalysisResult.hollowedThickness > 0 && (
              <div className="row d-flex align-items-center">
                <p className="col-5 text-right">
                  <strong>Wall Thickness:</strong>
                </p>
                <p className="col-7">
                  {m.cadAnalysisResult.hollowedThickness.toFixed(2) + " mm"}
                </p>
              </div>
            )}
            <div className="row d-flex align-items-center">
              <p className="col-5 text-right">
                <strong>Volume:</strong>
              </p>
              <p className="col-7">
                {m.cadAnalysisResult.volume.toFixed(2) + " mm³"}
              </p>
            </div>
            <div className="row d-flex align-items-center">
              <p className="col-5 text-right">
                <strong>Surface Area:</strong>
              </p>
              <p className="col-7">
                {m.cadAnalysisResult.surfaceArea.toFixed(2) + " mm²"}
              </p>
            </div>
            <div className="row d-flex align-items-center">
              <p className="col-5 text-right">
                <strong>Dimensions (X x Y x Z):</strong>
              </p>
              <p className="col-7">
                {m.cadAnalysisResult.dimensionX.toFixed(2) +
                  " mm x " +
                  m.cadAnalysisResult.dimensionY.toFixed(2) +
                  " mm x " +
                  m.cadAnalysisResult.dimensionZ.toFixed(2) +
                  " mm"}
              </p>
            </div>
          </>
        )}
      </>
    );

    const modelCategoriesOptionsRender =
      this.props.appState.modelCategories &&
      this.props.appState.modelCategories
        .sort((a, b) => a.displayIndex - b.displayIndex)
        .map((modelCategory) => {
          return (
            <option key={modelCategory.id} value={modelCategory.id}>
              {modelCategory.description}
            </option>
          );
        });

    const modelCategoriesRender =
      this.props.appState.modelCategories && !this.state.deleted ? (
        <select
          className="custom-select-lg"
          name="modelCategory"
          onChange={this.onModelCategoryChange}
          value={this.state.selectedModelCategory}
        >
          {modelCategoriesOptionsRender}
        </select>
      ) : (
        this.props.appState.modelCategories &&
        m.modelCategoryId &&
        this.props.appState.modelCategories.find(
          (mc) => mc.id === m.modelCategoryId
        ) && (
          <p>
            {
              this.props.appState.modelCategories.find(
                (mc) => mc.id === m.modelCategoryId
              ).description
            }
          </p>
        )
      );

    const deleteDialog = this.state.showDeleteDialog && (
      <div className={"dialog-mask"}>
        <div className={"dialog-box"}>
          <div className="title">Delete Model?</div>
          <p>Are you sure you want to delete this model?</p>
          <p>This action cannot be undone.</p>
          <div className="left-cont">
            <button
              className="btn btn-primary cng3d-general-button highlighted"
              onClick={() => {
                this.setState({ showDeleteDialog: false });
              }}
            >
              Cancel
            </button>
          </div>
          <div className="right-cont">
            <button
              className="btn btn-primary cng3d-general-button caution"
              onClick={this.onDeleteModelConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {m && (
          <div className="container-fluid">
            <LoadingOverlay loading={this.state.loading} />
            <div className="row">
              <div className="portal-container d-flex align-items-center">
                {/* Detail Section */}
                <div className="col-12 col-md-6 portal-contents p-4 absolute">
                  <div className="col-12">
                    <h3>
                      <strong>Model Details</strong>
                    </h3>

                    <div className="row mb-2">
                      <img
                        src={m.thumbnailPath}
                        className="detail-image"
                        alt="model thumbnail"
                      />
                    </div>

                    {referenceRender}

                    {descriptionRender}

                    {m.customerId === null && (
                      <div className="row d-flex align-items-center">
                        <div className="col-5 text-right">
                          <p>
                            <strong>Category:</strong>
                          </p>
                        </div>
                        <div className="col-7">{modelCategoriesRender}</div>
                      </div>
                    )}

                    {m.customerId === null && (
                      <div className="row d-flex align-items-center">
                        <div className="col-5 text-right">
                          <label htmlFor="isNew">
                            <p>Is New:</p>
                          </label>
                        </div>
                        <div className="col-7">
                          <input
                            id="isNew"
                            type="checkbox"
                            checked={this.state.isNew}
                            onChange={async ({ target }) => {
                              const { checked } = target;
                              await this.setState({ isNew: checked });
                              this.updateModel("isNew");
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {m.customerId === null && (
                      <div className="column d-flex align-items-center">
                        <div className="col-5 text-right">
                          <label htmlFor="isTopN">
                            <p>Is Trending:</p>
                          </label>
                        </div>
                        <div className="col-7">
                          <input
                            id="isTopN"
                            type="checkbox"
                            checked={this.state.isTopN}
                            onChange={async ({ target }) => {
                              const { checked } = target;
                              await this.setState({ isTopN: checked });
                              this.updateModel("isTopN");
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {((this.state.adminNotesString.length > 0 &&
                      !isUserAdmin(this.props.appState)) ||
                      isUserAdmin(this.props.appState)) && (
                      <div className="row d-flex align-items-start">
                        {adminNoteRender}
                      </div>
                    )}

                    <div className="row d-flex align-items-center">
                      <p className="col-5 text-right">
                        <strong>Uploaded:</strong>
                      </p>
                      <p className="col-7">
                        {" "}
                        {GetShortDateString(m.createdDate)}
                      </p>
                    </div>

                    {m.erpCustomerReference && (
                      <div className="row d-flex align-items-center">
                        <p className="col-5 text-right">
                          <strong>Customer:</strong>
                        </p>
                        <p className="col-7"> {m.erpCustomerReference}</p>
                      </div>
                    )}

                    <br />
                    <h3>
                      <strong>Analysis Results</strong>
                    </h3>
                    {analysisResultsRender}
                  </div>

                  <div className="row"></div>
                </div>

                {/* Model Viewer */}
                <div className="col-md-6 portal-rendering-engine p-4 d-none d-md-flex align-items-center text-center">
                  {m.filePath ? (
                    <Model3dViewer
                      fileUri={m.filePath}
                      fileId={this.state.model.customerFileId}
                      accessToken={this.props.appState.accessToken}
                      showThumbnailButton={false} //!this.state.deleted}
                      analysing={false}
                      key={this.state.modelViewerKey}
                      backgroundColor="#E6F0F3"
                      backgroundAlpha={0}
                    />
                  ) : (
                    <img
                      className="default-icon-arrangement"
                      alt="model viewer holding"
                      src="/images/cube-elevated.svg"
                    />
                  )}
                  {!this.state.deleted && (
                    <>
                      <div className="row buttons">
                        <button
                          className="btn btn-primary cng3d-general-button"
                          onClick={this.onDownloadCadFileClick}
                        >
                          Download Repaired STL File
                        </button>
                        <button
                          className="btn btn-primary cng3d-general-button"
                          onClick={this.onDownloadOriginalCadFileClick}
                        >
                          Download Original CAD File
                        </button>
                      </div>
                      <div className="row buttons">
                        <div>
                          <input
                            hidden
                            ref={this.replaceCadModel}
                            type="file"
                            onChange={this.onFileChange}
                            accept=".stl"
                          />
                          <button
                            className="btn btn-primary cng3d-general-button"
                            onClick={() => this.replaceCadModel.current.click()}
                          >
                            Replace CAD Model
                          </button>
                        </div>

                        <div>
                          <input
                            hidden
                            ref={this.replaceThumbnail}
                            type="file"
                            onChange={this.onThumbnailFileUpload}
                            accept=".bmp, .gif, .jpg, .jpeg, .png"
                          />
                          <button
                            className="btn btn-primary cng3d-general-button"
                            onClick={(e) =>
                              this.replaceThumbnail.current.click(e)
                            }
                          >
                            Replace Thumbnail
                          </button>
                        </div>
                        {cadUploadAndDataRender}
                      </div>

                      {m.customerId === null && (
                        <div className="row">
                          <button
                            className="btn btn-primary cng3d-general-button caution"
                            onClick={this.onDeleteModel}
                          >
                            Delete Model
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {deleteDialog}
          </div>
        )}
      </>
    );
  }
}
