import Api from "./api";

export default class ApiCadAnalysis extends Api {
  static async transform(accessToken, fileId) {
    const data = await this.post(
      `/api/CadAnalysis/Transform/${fileId}`,
      accessToken,
      true
    );
    return data;
  }

  static async analysis(accessToken, fileId) {
    const data = await this.post(
      `/api/CadAnalysis/Analysis/${fileId}`,
      accessToken,
      true
    );
    return data;
  }

  static async repair(accessToken, fileId) {
    const data = await this.post(
      `/api/CadAnalysis/Repair/${fileId}`,
      accessToken,
      true
    );
    return data;
  }

  static async scale(accessToken, fileId, scaleFactor) {
    const data = await this.post(
      `/api/CadAnalysis/Scale/${fileId}?scaleFactor=${scaleFactor}`,
      accessToken,
      true
    );
    return data;
  }

  static async hollow(accessToken, fileId, shellThickness) {
    const data = await this.post(
      `/api/CadAnalysis/Hollow/${fileId}?shellThickness=${shellThickness}`,
      accessToken,
      true
    );
    return data;
  }

  static async generateThumbnail(accessToken, fileId) {
    const data = await this.post(
      `/api/CadAnalysis/GenerateThumbnail/${fileId}`,
      accessToken,
      true
    );
    return data;
  }
}
