import Api from './api';

export default class ApiAdmin extends Api {
   static async getUsers(accessToken) {
      const data = await this.get(`/api/users/`, accessToken);
      return data;
   }

   static async getUser(accessToken, userId) {
      const data = await this.get(`/api/users/${userId}`, accessToken);
      return data;
   }

   static async getAdminUsers(accessToken, allGroups) {
      const adminGroup = allGroups.find((el) => el.groupName === process.env.REACT_APP_ADMIN_GROUP);
      const data = await this.get(`/api/groups/${adminGroup.groupId}/members`, accessToken);
      return data;
   }

   static async getSuperAdminUsers(accessToken, allGroups) {
      const superAdminGroup = allGroups.find((el) => el.groupName === process.env.REACT_APP_USER_ACCESS_ADMIN_GROUP);
      const data = await this.get(`/api/groups/${superAdminGroup.groupId}/members`, accessToken);
      return data;
   }

   static async getCompanyDirectoryUsers(accessToken) {
      let data = await this.get('/api/users', accessToken);
      if(data) data = data.filter(f => f.upn && !f.upn.includes("#EXT#"));
      return data;
   }

   static async getLoggedInUser(accessToken) {
      const data = await this.get(`/api/users/me`, accessToken);
      return data;
   }

   static async getAllGroups(accessToken) {
      const data = await this.get(`/api/groups`, accessToken);
      return data;
   }

   static async getGroupsOfUser(accessToken, userId) {
      const data = await this.get(`/api/users/${userId}/groups`, accessToken);
      return data;
   }

   static async getGroupsOfLoggedInUser(accessToken) {
      const data = await this.get(`/api/users/my/groups`, accessToken);
      return data;
   }

   static async createUser(accessToken, user) {
      const data = await this.post('/api/users/companyuser', accessToken, user, true);
      return data;
   }

   static async toggleAdminUserStatus(accessToken, user, allGroups, enable) {
      return enable
         ? await this.assignUserToAdminGroup(accessToken, user.userId, allGroups)
         : await this.removeUserFromAdminGroup(accessToken, user.userId, allGroups);
   }

   static async assignUserToAdminGroup(accessToken, userId, allGroups) {
      const adminGroup = allGroups.find((el) => el.groupName === process.env.REACT_APP_ADMIN_GROUP);
      const assignmentData = { userId, groupId: adminGroup.groupId };
      const data = await this.post('/api/users/userGroupAssignment', accessToken, assignmentData);
      return data;
   }

   static async removeUserFromAdminGroup(accessToken, userId, allGroups) {
      const adminGroup = allGroups.find((el) => el.groupName === process.env.REACT_APP_ADMIN_GROUP);
      const assignmentData = { userId, groupId: adminGroup.groupId };
      const data = await this.delete('/api/users/userGroupAssignment', accessToken, assignmentData);
      return data;
   }
}
