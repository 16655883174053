import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import ModelThumbnail from './thumbnail';
import SearchPanel from '../../elements/search-panel';
import Api from '../../api/model';
import LoadingOverlay from '../loading-overlay';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSortAmountDown, faSortAmountUpAlt } from '@fortawesome/free-solid-svg-icons';

export default class CustomerModelList extends React.Component {
   state = {
      models: [],
      sortCol: 'CreatedDate',
      searchText: '',
      loading: false,
      showGroupMode: false,
      showArchived: false,
      grouped: [],
      hasMore: false,
      title: '',
      sortDescending: true,
   };

   init() {
      this.updateTitle();
      this.getModels(0);
   }

   componentDidMount() {
      if (this.props.appState.accessToken) this.init();
   }

   componentDidUpdate(prevProps, prevState) {
      if (
         (this.props.appState.accessToken && prevProps.appState.accessToken !== this.props.appState.accessToken) ||
         prevState.sortCol !== this.state.sortCol ||
         prevState.sortAsc !== this.state.sortAsc ||
         prevState.searchText !== this.state.searchText
      ) {
         this.init();
      }
   }

   updateTitle = () => {
      this.setState({ title: 'Customer Models' });
   };

   getModels = async (pageNumber) => {
      let filter = {};
      
      if (this.props.appState.customerId) {
          filter.customerId = this.props.appState.customerId.toString();
          filter.modelType = 'cad';
      } else {
          filter.customerId = null;
          filter.modelType = 'physicalMould';
      }
      
      const searchObject = {
         from: pageNumber * this.props.appState.pageSize,
         size: this.props.appState.pageSize,
         contains: this.state.searchText,
         filter,
         reverseFilter: !this.props.appState.customerId,
         orderBy: [
            {
               columnName: this.state.sortCol,
               sort: this.state.sortDescending ? 'Descending' : 'Ascending',
            },
         ],
      };
      await this.setState({ loading: true });
      var modelsLoaded = await Api.getModels(this.props.appState.accessToken, searchObject);
      const hasMore = modelsLoaded && modelsLoaded.length === this.props.appState.pageSize;
      let models = pageNumber === 0 ? modelsLoaded : [...this.state.models, ...modelsLoaded];
      await this.setState({ models, hasMore, loading: false });
   };

   toggleArchived = (e) => {
      this.setState({ showArchived: e.target.checked });
   };

   toggleSortDirection = async () => {
       await this.setState({ sortDescending: !this.state.sortDescending, models: [] });
       await this.getModels(0);
   }

   onSearchSubmit = (searchText) => {
      this.setState({ searchText, models: [] });
   };

   render() {
      const listRender =
         this.state.models &&
         (this.state.models.length > 0
            ? this.state.models.map((model) => {
                 return (
                    <ModelThumbnail
                       appState={this.props.appState}
                       key={model.modelId}
                       model={model}
                    />
                 );
              })
            : !this.state.loading && <div className='error'>The search parameters returned no results</div>);

      return (
        <div className='gal3d-body font-stack-s0 enable-scroll-styling'>
            <LoadingOverlay loading={this.state.loading}/>
            <div className='container-fluid'>
                <div className='gallery-detail-contents mt-0 mt-md-5'>
                    <div className='row'>
                        <div className='col-12 detail-contents p-4'>
                            <div className='row pb-4 d-flex flex-row-reverse flex-md-row'>
                                <div className='col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center'>
                                <h1 className='text-center text-md-left'><strong>Customer Models</strong></h1>
                                </div>
                                <div className='col-12 col-md-6 align-items-end justify-content-md-end justify-content-center d-flex'>
                                    <div className='search_container align-items-center d-flex mt-3 mt-md-5'>                           
                                        <SearchPanel onSearchSubmit={this.onSearchSubmit} />

                                        {/*<button className='dropdown text-dark-blue btn-link col-xs-12 sort-view' onClick={this.toggleSortDirection}>
                                            {this.state.sortDescending ? 
                                            <>
                                                <FontAwesomeIcon icon={faSortAmountDown} className='icons'/> Latest
                                            </> : 
                                            <>
                                                <FontAwesomeIcon icon={faSortAmountUpAlt} className='icons'/> Oldest
                                            </>}
                                            </button>*/}

                                    </div>
                                </div>
                            </div>

                            <div className='row detailed-gallery-area font-stack-s9 col-md-12 text-justify enable-scroll-styling' ref={(ref) => this.scrollParentRef = ref}>
                                    <InfiniteScroll 
                                        loadMore={this.getModels} 
                                        hasMore={this.state.hasMore && !this.state.loading} 
                                        getScrollParent={() => this.scrollParentRef} 
                                        useWindow={false}
                                        className='col-12'
                                    >
                                        {listRender}
                                    </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
   }
}
