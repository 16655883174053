import Api from "./api";

export default class ApiModel extends Api {
  static async create(accessToken, model) {
    const data = await this.post("/api/model", accessToken, model, true);
    return data;
  }

  static async deleteModel(accessToken, id) {
    const data = await this.delete(`/api/model/delete/${id}`, accessToken);
    return data;
  }

  static async createModelCategory(accessToken, modelCategoryName) {
    const success = await this.post(
      "/api/model/category/add",
      accessToken,
      modelCategoryName,
      false
    );
    return success;
  }

  static async deleteModelCategory(accessToken, id) {
    const success = await this.delete(
      `/api/model/category/delete/${id}`,
      accessToken
    );
    return success;
  }

  static async updateModelCategory(accessToken, displayIdUpdates) {
    const success = await this.post(
      `/api/model/category`,
      accessToken,
      displayIdUpdates,
      false
    );
    return success;
  }

  static async getModels(accessToken, searchObject) {
    const data = await this.post(
      "/api/model/search",
      accessToken,
      searchObject,
      true
    );
    return data;
  }

  static async getGenericModels(searchObject) {
    const data = await this.post(
      "/api/model/searchGeneric",
      null,
      searchObject,
      true
    );
    return data;
  }

  static async getModel(accessToken, id) {
    const data = await this.get(`/api/model/${id}`, accessToken);
    return data;
  }

  static async updateModel(accessToken, model) {
    const success = await this.put("/api/model/", accessToken, model);
    return success;
  }

  static async groupModels(accessToken, models) {
    const success = await this.put("/api/model/group", accessToken, models);
    return success;
  }

  static async addAdminNote(accessToken, id, note) {
    const success = await this.post(`/api/model/note/${id}`, accessToken, note);
    return success;
  }

  // static async getPricingAttributes() {
  //    const data = await this.get('/api/Customer/pricingAttributes');
  //    return data;
  // }

  // static async updateCustomerState(id, state) {
  //    const success = await this.put(`/api/Customer/state/${id}`, state);
  //    return success;
  // }

  // static async updateCustomerPricingAttribute(customerId, attributeId, attributeValue) {
  //    const success = await this.put(`/api/Customer/pricingAttribute/${customerId}/${attributeId}/${attributeValue}`);
  //    return success;
  // }
}
