import React from "react";
import { Link } from "react-router-dom";
import Model3dViewer from "../3d-viewer/model-3d-viewer";
import LoadingOverlay from "../loading-overlay";

export default class ModelAdd3Submit extends React.Component {
  state = {
    analysisId: null,
    analysisStatus: [],
  };

  render() {
    const modelCategoriesOptionsRender =
      this.props.appState.modelCategories &&
      this.props.appState.modelCategories.map((modelCategory) => {
        return (
          <option key={modelCategory.id} value={modelCategory.id}>
            {modelCategory.description}
          </option>
        );
      });

    const modelCategoriesRender = this.props.appState.modelCategories ? (
      <select
        className="custom-select-lg"
        name="modelCategory"
        onChange={this.props.changeModelCategory}
        value={this.props.modelCategoryId}
      >
        {modelCategoriesOptionsRender}
      </select>
    ) : (
      <></>
    );

    const analysisResultsRender = this.props.results && (
      <>
        <div className="row d-flex align-items-center">
          <p className="col-5 text-right">
            <strong>Repair Needed:</strong>
          </p>
          <p className="col-7">
            {this.props.results.repairNeeded || this.props.results.startRepair
              ? "Yes"
              : "No"}
          </p>
        </div>
        {(this.props.results.repairNeeded ||
          this.props.results.startRepair) && (
          <div className="row d-flex align-items-center">
            <p className="col-5 text-right">
              <strong>Repair Successful:</strong>
            </p>
            <p className="col-7">
              {this.props.results.repairSuccess ? "Yes" : "No"}
            </p>
          </div>
        )}
        <div className="row d-flex align-items-center">
          <p className="col-5 text-right">
            <strong>Volume:</strong>
          </p>
          <p className="col-7">
            {this.props.results.volume.toFixed(2) + " mm³"}
          </p>
        </div>
        <div className="row d-flex align-items-center">
          <p className="col-5 text-right">
            <strong>Surface Area:</strong>
          </p>
          <p className="col-7">
            {this.props.results.surfaceArea.toFixed(2) + " mm²"}
          </p>
        </div>
        <div className="row d-flex align-items-center">
          <p className="col-5 text-right">
            <strong>Dimensions (X x Y x Z):</strong>
          </p>
          <p className="col-7">
            {this.props.results.dimensionX.toFixed(2) +
              " mm x " +
              this.props.results.dimensionY.toFixed(2) +
              " mm x " +
              this.props.results.dimensionZ.toFixed(2) +
              " mm"}
          </p>
        </div>
      </>
    );

    return (
      <div className="container-fluid">
        <LoadingOverlay loading={this.state.loading} />
        <div className="row">
          <div className="portal-container d-flex align-items-center">
            {/* Detail Section */}
            <div className="col-12 col-md-6 portal-contents p-4 absolute">
              <div className="col-12">
                <h3>
                  <strong>Analysis Results</strong>
                </h3>
                {analysisResultsRender}

                <h3>
                  <strong>Model Details</strong>
                </h3>

                {this.props.submitted ? (
                  <>
                    <div className="row d-flex align-items-center">
                      <p className="col-5 text-right">
                        <strong>Reference:</strong>
                      </p>
                      <p className="col-7">{this.props.reference}</p>
                    </div>

                    {!this.props.addCustomer && (
                      <div className="row d-flex align-items-center">
                        <p className="col-5 text-right">
                          <strong>Category:</strong>
                        </p>
                        <p className="col-7">
                          {
                            Object.values(
                              this.props.appState.modelCategories
                            ).find(
                              (mc) =>
                                mc.id === parseInt(this.props.modelCategoryId)
                            ).description
                          }
                        </p>
                      </div>
                    )}

                    <p className="row d-flex flex-row justify-content-center text-orange">
                      This model has been added to the{" "}
                      {this.props.addCustomer ? "Customer" : "Cooksongold 3D"}{" "}
                      Library
                    </p>

                    <div className="row justify-content-center buttons">
                      <Link to={`/model/detail/${this.props.modelId}`}>
                        <button
                          className="btn btn-primary cng3d-general-button"
                          onClick={this.onDownloadOriginalCadFileClick}
                        >
                          Edit Model
                        </button>
                      </Link>
                      <button
                        className="btn btn-primary cng3d-general-button"
                        onClick={this.props.addNewModel}
                      >
                        Add New Model
                      </button>
                      <Link to="/">
                        <button className="btn btn-primary cng3d-general-button">
                          Return Home
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row d-flex align-items-center">
                      <div className="col-5 text-right">
                        <p>
                          <strong>Reference:</strong>
                        </p>
                      </div>
                      <div className="col-7">
                        <input
                          name="reference"
                          className="data"
                          value={this.props.reference}
                          onChange={this.props.inputChange}
                          maxLength={30}
                        />
                      </div>
                    </div>

                    {!this.props.addCustomer && (
                      <div className="row d-flex align-items-start">
                        <p className="col-5 text-right">
                          <strong>Description:</strong>
                        </p>

                        <div className="col-7">
                          <textarea
                            name="userNote"
                            className="data enable-scroll-styling"
                            value={this.props.userNote}
                            onChange={this.props.inputChange}
                          />
                        </div>
                      </div>
                    )}

                    {!this.props.addCustomer && (
                      <div className="row d-flex align-items-center">
                        <div className="col-5 text-right">
                          <p>
                            <strong>Category:</strong>
                          </p>
                        </div>
                        <div className="col-7">{modelCategoriesRender}</div>
                      </div>
                    )}

                    {(this.props.results.repairNeeded ||
                      this.props.results.startRepair) &&
                    !this.props.results.repairSuccess ? (
                      <div className="row justify-content-center text-center">
                        <br />
                        <p className="col-12 text-orange">
                          This model has printability isses that couldn't be
                          automatically repaired. Please repair the file
                          manually and try uploading it again.
                        </p>
                      </div>
                    ) : this.props.results.uploadFailed ? (
                      <div className="row justify-content-center text-center">
                        <br />
                        <p className="col-12 text-orange">
                          There was an issue uploading the file. Please try
                          again.
                        </p>
                      </div>
                    ) : this.props.exportFailed ? (
                      <div className="row justify-content-center text-center">
                        <br />
                        <p className="col-12 text-orange">
                          There was an issue saving the printable file. Please
                          try again.
                        </p>
                      </div>
                    ) : (
                      <div className="row d-flex justify-content-center">
                        <br />
                        <button
                          className={
                            this.props.reference.trim() !== ""
                              ? "btn btn-primary cng3d-general-button highlighted"
                              : "btn btn-primary cng3d-general-button disabled"
                          }
                          onClick={this.props.submit}
                        >
                          Add Model To Library
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Model Viewer */}
            <div className="col-md-6 portal-rendering-engine p-4 d-none d-md-flex align-items-center text-center">
              <Model3dViewer
                fileUri={this.props.fileUri}
                fileId={this.props.fileId}
                accessToken={this.props.appState.accessToken}
                showThumbnailButton={false}
                analysing={false}
                key={this.state.modelViewerKey}
                backgroundColor="#E6F0F3"
                backgroundAlpha={0}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
