import React from 'react';

export default class LoadingCube extends React.Component {
   render() {
      return (
        <div className='container-cube'>
            <div className='cube'>
                <div className='sides'>
                    <div className='top'></div>
                    <div className='right'></div>
                    <div className='bottom'></div>
                    <div className='left'></div>
                    <div className='front'></div>
                    <div className='back'></div>
                </div>
            </div>
            <br />
            <div className='text'>{this.props.text === null ? ' ' : this.props.text}</div>
        </div>
      );
   }
}
