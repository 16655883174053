import React from 'react';
import { isUserSuperAdmin } from '../utils/user';

import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faFolder, faFolderPlus, faChartLine, faUsers, faSignOutAlt, faBan, faFolderOpen, faArchive, faCaretDown, faPlusSquare, faUser, faUserCog, faBars } from '@fortawesome/free-solid-svg-icons';

class Menu extends React.Component {
   render() {
      return (
        <nav className='navbar sticky-top navbar-expand-lg cng3d-navbar bg-powder-blue'>
            <a className='navbar-brand' href='/#'><img src='../images/Cooksongold3D_Logo.svg' alt='Cooksongold 3D Logo'/></a>
            <div className='nav-item pr-md-4'>
                <h2>Welcome, <strong>{this.props.account.name}</strong></h2>
                {this.props.customerName && 
                    <>
                        <div >Impersonating Account: <strong>{this.props.customerName}</strong></div>
                        <div className='impersonation-stop' onClick={this.props.onClearImpersonation}>
                            <FontAwesomeIcon icon={faBan} className='icons'/> Stop Impersonation
                        </div>
                    </>
                }
            </div>
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNav'
            aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon'>
                    <FontAwesomeIcon icon={faBars} className='icons'/>
                </span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
                <ul className='navbar-nav ml-auto'>
                    <li className='nav-item pr-md-4 d-block d-md-none'>
                        <a className='nav-link txt-black py-0 font-weight-bold' href='/#'><span>MENU</span></a>
                    </li>

                    <li className='nav-item pr-md-4 pt-4 pb-4 dropdown-nav'>
                        <Link 
                            to={{
                                pathname : this.props.appState.customerId ? '/model/list/generic' : '/',
                                search: this.props.appState.customerId ? '' : 'Cooksongold3dLibrary'
                            }}
                            className='nav-link with-icon txt-black py-0'
                        >
                            <FontAwesomeIcon icon={faFolder} className='icons'/> Cooksongold 3D Library 
                            {!this.props.appState.customerId && 
                                <>
                                    &nbsp;<FontAwesomeIcon icon={faCaretDown} className='caret-down'/>
                                </>
                            }
                            <span className='sr-only'>(current)</span>
                        </Link>
                        {!this.props.appState.customerId && (
                            <ul className='dropdown-nav-content glass'>
                                <li>
                                    <Link to='/model/addAdmin' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faFolderPlus} className='icons'/> Add New
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/model/list/generic' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faFolderOpen} className='icons'/> View Library
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/model/categoryManagement' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faArchive} className='icons'/> Category Management
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li className='nav-item pr-md-4 pt-4 pb-4 dropdown-nav'>
                        <Link 
                            to={{
                                pathname : this.props.appState.customerId ? '/' : '/model/list/customer',
                                search: this.props.appState.customerId ? 'CustomerModels' : ''
                            }}
                            className='nav-link with-icon txt-black py-0'
                        >
                            <FontAwesomeIcon icon={faCubes} className='icons'/> Customer Models
                            {this.props.appState.customerId && 
                                <>
                                    &nbsp;<FontAwesomeIcon icon={faCaretDown} className='caret-down'/>
                                </>
                            }
                            <span className='sr-only'>(current)</span>
                        </Link>
                        {this.props.appState.customerId && (
                            <ul className='dropdown-nav-content glass'>
                                <li>
                                    <Link to='/model/addCustomer' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faPlusSquare} className='icons'/> Add New
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/model/list/customer' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faCubes} className='icons'/> View Library
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li className='nav-item pr-md-4 pt-4 pb-4-4 dropdown-nav'>
                        <Link 
                            to={{
                                pathname : isUserSuperAdmin(this.props.appState) ? '/' : '/account/list-live',
                                search: 'Accounts'
                            }}
                            className='nav-link with-icon txt-black py-0'
                        >
                            <FontAwesomeIcon icon={faUsers} className='icons'/> {isUserSuperAdmin(this.props.appState) ? 'Accounts' : 'Customer Accounts'}
                            {isUserSuperAdmin(this.props.appState) && 
                                <>
                                    &nbsp;<FontAwesomeIcon icon={faCaretDown} className='caret-down'/>
                                </>
                            }
                            <span className='sr-only'>(current)</span>
                        </Link>
                        {isUserSuperAdmin(this.props.appState) && (
                            <ul className='dropdown-nav-content glass'>
                                <li>
                                    <Link to='/account/list-live' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faUser} className='icons'/> Customers
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/account/list-admin' className='nav-link with-icon txt-black py-0 mt-4 mb-4'>
                                        <FontAwesomeIcon icon={faUserCog} className='icons'/> Admins
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    {isUserSuperAdmin(this.props.appState) &&
                        <li className='nav-item pr-md-4 pt-4 pb-4'>
                            <Link to='/reports/model' className='nav-link  txt-black py-0'>
                                <FontAwesomeIcon icon={faChartLine} className='icons'/> Portal Usage
                            </Link>
                        </li>
                    }
                    <li className='nav-item pr-md-4 pt-4 pb-4'>
                        <Link to='' className='nav-link with-icon text-orange py-0' onClick={this.props.onSignOut}>
                            <FontAwesomeIcon icon={faSignOutAlt} className='icons'/> Sign Out
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
      );
   }
}

export default withRouter(Menu);
