import React from 'react';
import {Link} from "react-router-dom";

export default class PageNotFound extends React.Component {
   render() {
      return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='portal-container d-flex align-items-center'>

                    {/* Detail Section */}
                    <div className='col-12 portal-contents p-4 absolute'>
    
                        <div className='col-12'>
                            <h3><strong>Page Not Found</strong></h3>                       
                        </div>

                        <h4 className='col-12 p-4 d-flex justify-content-center'>Unable to find the requested resource</h4>

                        <div className='col-12 d-flex justify-content-center'>
                            <Link to='/'>
                                <button className='btn btn-primary cng3d-general-button highlighted'>Return Home</button>
                            </Link>
                        </div>
    
                    </div>           

                </div>
            </div>
        </div>
      );
   }
}
