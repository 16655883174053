import React from "react";

export default class SearchPanel extends React.Component {
  state = { searchString: "", changed: false };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.searchString !== this.state.searchString &&
      this.state.searchString.length === 0
    )
      this.onSearchSubmit();
  }

  onHandleChange = (e) => {
    this.setState({ searchString: e.target.value, changed: true });
  };

  onKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.onSearchSubmit();
    }
  };

  onSearchSubmit = () => {
    this.state.changed && this.props.onSearchSubmit(this.state.searchString);
    this.setState({ changed: false });
  };

  onSearchClear = () => {
    this.setState({ searchString: "", changed: true });
  };

  render() {
    return (
      <div className="col">
        <div className="input-group mb-3">
          <input
            className="form-control search_controle"
            placeholder="Search"
            value={this.state.searchString}
            onChange={this.onHandleChange}
            onKeyUp={this.onKeyUp}
          />
          <span className="input-group-append bg-white">
            <button
              className="btn search_controle border-left-0"
              type="button"
              onClick={this.onSearchSubmit}
            >
              <i className="fas fa-search"></i>
            </button>
          </span>
        </div>
      </div>
    );
  }
}
