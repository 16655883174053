import React from 'react';
import { GetMultilineString } from '../utils/string';
import { isUserSuperAdmin } from '../utils/user';

export default class Home extends React.Component {
    state = {
        currentPage: '?Cooksongold3dLibrary',
    };

    init() {
        if (this.props.location.search !== '') {
            this.setState({ currentPage: this.props.location.search });
        } else {
            this.setState({ currentPage: this.props.appState.customerId ? '?CustomerModels' : '?Cooksongold3dLibrary' });
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.init();
        }
    }

    render() {

    const cooksongold3DLibrary = 
        <div className='item featured-slide-01 d-flex align-items-center'>
            <div className='cng3d-slider-content-wrap col-md-7'>
                <h1 className='slider-primary-color'>Cooksongold 3D Library</h1>
                <h2>View and manage models in the Cooksongold 3D library</h2>

                <div className='more-info-linker'>
                    <img src='images/linker.svg' alt='' className='linker d-none d-md-block' />
                    <img src='images/linker-mobile.svg' alt='' className='linker-mobile d-block d-md-none' />

                    <div className='link-wrapper col-12'>
                        <div className='single-link link01'>
                            <a href='#/model/addAdmin' className='learn d-flex justify-content-center'><i className='fa fa-folder-plus'></i></a>
                            <span><strong>{GetMultilineString('Add\nNew')}</strong></span>
                        </div>

                        <div className='single-link link02'>
                            <a href='#/model/list/generic' className='learn d-flex justify-content-center'><i className='fa fa-folder-open'></i></a>
                            <span><strong>{GetMultilineString('View\nLibrary')}</strong></span>
                        </div>

                        <div className='single-link link03'>
                            <a href='#/model/categoryManagement' className='learn d-flex justify-content-center'><i className='fa fa-archive'></i></a>
                            <span><strong>{GetMultilineString('Manage\nCategories')}</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    const customerModels = 
        <div className='item featured-slide-01 d-flex align-items-center'>
            <div className='cng3d-slider-content-wrap col-md-7'>
                <h1 className='slider-primary-color'>Customer Models</h1>
                <h2>View and manage models uploaded by customers</h2>

                <div className='more-info-linker'>
                    <img src='images/linker.svg' alt='' className='linker d-none d-md-block' />
                    <img src='images/linker-mobile.svg' alt='' className='linker-mobile d-block d-md-none' />

                    <div className='link-wrapper col-12'>
                        {this.props.appState.customerId && (
                            <div className='single-link link01'>
                                <a href='#/model/addCustomer' className='learn d-flex justify-content-center'><i className='fa fa-plus-square'></i></a>
                                <span><strong>{GetMultilineString('Add\nNew')}</strong></span>
                            </div>
                        )}

                        <div className='single-link link02'>
                            <a href='#/model/list/customer' className='learn d-flex justify-content-center'><i className='fa fa-cubes'></i></a>
                            <span><strong>{GetMultilineString('View\nLibrary')}</strong></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    const accounts = 
        <div className='item featured-slide-01 d-flex align-items-center'>
            <div className='cng3d-slider-content-wrap col-md-7'>
                <h1 className='slider-primary-color'>Accounts</h1>
                <h2>View and manage customer &amp; admin accounts</h2>

                <div className='more-info-linker'>
                    <img src='images/linker.svg' alt='' className='linker d-none d-md-block' />
                    <img src='images/linker-mobile.svg' alt='' className='linker-mobile d-block d-md-none' />

                    <div className='link-wrapper col-12'>
                        {isUserSuperAdmin(this.props.appState) && (
                            <div className='single-link link01'>
                                <a href='#/account/list-admin' className='learn d-flex justify-content-center'><i className='fa fa-user-cog'></i></a>
                                <span><strong>{GetMultilineString('Admins')}</strong></span>
                            </div>
                        )}

                        <div className='single-link link02'>
                        <a href='#/account/list-live' className='learn d-flex justify-content-center'><i className='fa fa-user'></i></a>
                            <span><strong>{GetMultilineString('Customers')}</strong></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        let infoRender = null;
        switch (this.state.currentPage) {
            case '?Cooksongold3dLibrary':
                infoRender = cooksongold3DLibrary;
                break;
            case '?CustomerModels':
                infoRender = customerModels;
                break;
            case '?Accounts':
                infoRender = accounts;
                break;
            default:
                break;
        }

        return (
            <div className='featured-slide mt-5 mt-md-0 pt-5 pt-md-0 d-flex justify-content-center'>
                <div className='row slider-navigation col-12-sm col-6-md'>
                    <div className='canvas-wrapper'>

                        {isUserSuperAdmin(this.props.appState) &&
                            <a className='circle-navigator home placement-default' id='home' href='#reports/model'>
                                <span className='canvas'>
                                <svg viewBox='0 0 137 135' xmlns='http://www.w3.org/2000/svg'>
                                        <circle className='circle outer' r='45' />
                                        <circle className='circle inner' r='45' />
                                        <image className='circle-icon' href='images/icons/chart-line-solid.svg' />
                                    </svg>
                                </span>
                                <span className='labeling'>
                                    <h1>Portal Usage</h1>
                                </span>
                            </a>
                        }

                        <a className='circle-navigator create placement-three' id='CustomerModels' 
                            href={this.props.appState.customerId ? '/#' : '#model/list/customer'} 
                            onClick={() => this.setState({ currentPage : '?CustomerModels'}) }
                        >
                            <span className='canvas'>
                                <svg viewBox='0 0 137 137' xmlns='http://www.w3.org/2000/svg'>
                                    <circle className='circle outer' r='45' />
                                    <circle className='circle inner' r='45' />
                                    <image className='circle-icon' href='images/icons/cubes-solid.svg' scale='2x'/>
                                </svg>
                            </span>
                            <span className='labeling'>
                                <h1>Customer Models</h1>
                            </span>
                        </a>


                        <a className='circle-navigator upload placement-two' id='Accounts'                            
                            href={isUserSuperAdmin(this.props.appState) ? '/#' : '#account/list-live'} 
                            onClick={() => this.setState({ currentPage : '?Accounts'}) }
                        >
                            <span className='canvas'>
                                <svg viewBox='0 0 137 137' xmlns='http://www.w3.org/2000/svg'>
                                    <circle className='circle outer' r='45' />
                                    <circle className='circle inner' r='45' />
                                    <image className='circle-icon' href='images/icons/users-solid.svg' />
                                </svg>
                            </span>
                            <span className='labeling'>
                                <h1>Accounts</h1>
                            </span>
                        </a>

                        <a className='circle-navigator print placement-featured' id='Cooksongold3dLibrary'                            
                            href={this.props.appState.customerId ? '#model/list/generic' : '#'} 
                            onClick={() => this.setState({ currentPage : '?Cooksongold3dLibrary'}) }
                        >
                            <span className='canvas'>
                                <svg viewBox='0 0 137 137' xmlns='http://www.w3.org/2000/svg'>
                                    <circle className='circle outer' r='45' />
                                    <circle className='circle inner' r='45' />
                                    <image className='circle-icon' href='images/icons/folder-solid.svg' />
                                </svg>
                            </span>
                            <span className='labeling'>
                                <h1>Cooksongold 3D Library</h1>
                            </span>
                        </a>

                        <div className='the-orbit-canvas'>
                            <svg viewBox='0 0 137 137' xmlns='http://www.w3.org/2000/svg'>
                                <image className='the-orbit curve-one' href='images/dashed-curve-01.svg' />
                                <image className='the-orbit curve-two' href='images/dashed-curve-02.svg' />
                                <image className='the-orbit curve-three' href='images/dashed-curve-03.svg' />
                            </svg>
                        </div>

                    </div>
                </div>

                <div className='row no-gutters slider-wrapper d-flex align-items-center col-12-sm'>
                    {infoRender}
                </div>

            </div>
        );
    }
}
