import React from "react";
import ApiFile from "../../api/file";
import ApiCadAnalysis from "../../api/cad-analysis";
import Model3dViewer from "../3d-viewer/model-3d-viewer";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { PulseLoader } from "react-spinners";
import LoadingCube from "../../elements/loading-cube";
import { GetFileExtension } from "../../utils/string";

const signalR = require("@microsoft/signalr");

export default class ModelAdd1Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploaded: false,
      patternChanged: false,
      error: "",
      warning: "",
      customerFileId: null,
      fileUri: null,
      uploading: false,
      connection: null,
    };

    this.onDrop = async (selectedFile) => {
      await this.onFileChange(selectedFile[0]);
      await this.onFileUpload();
    };

    this.selectCadFile = React.createRef();
  }

  onFileChange = (event) => {
    const selectedFile = event;
    const error =
      selectedFile.size > 50 * 1.028e6
        ? "Error: File must be less than 50MB"
        : "";
    const warning =
      selectedFile.size > 2e7
        ? "Warning: Files greater than 20MB may take a while to analyse"
        : "";
    this.setState({ error, warning, selectedFile });
  };

  onFileUpload = async () => {
    await this.setState({ uploading: true });
    this.props.changeUploadingState(true);

    const formData = new FormData();
    formData.append("filePayload", this.state.selectedFile);

    var customerFileId;
    if (this.props.addCustomer) {
      customerFileId = await ApiFile.uploadCadModel(
        this.props.appState.accessToken,
        this.props.appState.customerId,
        formData
      );
    } else {
      customerFileId = await ApiFile.uploadGenericCadModel(
        this.props.appState.accessToken,
        formData
      );
    }

    if (customerFileId > 0) {
      // Transform file
      if (
        GetFileExtension(this.state.selectedFile.name.toLowerCase()) !== "stl"
      ) {
        // Set up connection to signalR hub
        let connection = new signalR.HubConnectionBuilder()
          .withUrl(
            process.env.REACT_APP_HUB_HOST +
              `/AnalysisHub?access_token=${this.props.appState.accessToken}`,
            {
              skipNegotiation: true,
              transport: signalR.HttpTransportType.WebSockets,
            }
          )
          .withAutomaticReconnect()
          .build();

        connection.on("ReceiveMessage", (message) => {
          if (
            message.customerFileId === customerFileId &&
            message.finishTransform
          ) {
            this.completeUpload(message.customerFileId);
          }
        });

        this.setState({ connection });

        // Start signalR hub connection and add listeners
        const startHubConnection = async () => {
          try {
            if (connection) {
              await connection.start();

              await ApiCadAnalysis.transform(
                this.props.appState.accessToken,
                customerFileId
              );
            }
          } catch (e) {
            console.log("Connection failed: ", e);
            setTimeout(startHubConnection, 5000);
          }
        };

        await startHubConnection();
      } else {
        this.completeUpload(customerFileId);
      }
    } else {
      this.setState({ uploading: false });
      this.props.changeUploadingState(false);
    }
  };

  onAnalyseModelClick = () => {
    this.props.changePage();
  };

  completeUpload = async (fileId) => {
    if (this.state.selectedFile && this.state.selectedFile.name) {
      this.props.setDefaultReference(
        this.state.selectedFile.name.replace(/\.[^/.]+$/, "")
      );
    }
    this.setState({ uploaded: true, selectedFile: null, uploading: false });
    this.props.onNewFileId(fileId);
    this.props.changeUploadingState(false);
  };

  render() {
    const dropzoneRender = (
      <div className="col-12 drag-point p-5 mt-3 text-center">
        <Dropzone
          onDrop={this.onDrop}
          multiple={false}
          disabled={this.state.uploading}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="p-4">
                  {this.state.uploading ? (
                    <div className="row pt-5 pb-5 d-flex flex-row justify-content-center align-items-baseline">
                      {this.state.uploading && (
                        <p>Uploading File &nbsp; &nbsp;</p>
                      )}
                      <PulseLoader
                        color={"#0E4D80"}
                        loading={this.state.uploading}
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <FontAwesomeIcon size="5x" icon={faFileUpload} />
                      </div>
                      <span className="font-stack-s3 mt-n5">
                        <br />
                        Click and browse for a file or drag it here to upload
                      </span>
                    </>
                  )}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );

    const uploadFileRender = (
      <div className="row d-flex flex-row justify-content-center">
        {this.props.addCustomer ? (
          this.props.appState.customerId ? (
            dropzoneRender
          ) : (
            <div>
              <p className="col-12 text-orange">
                Please impersonate a customer to add a customer model
              </p>
            </div>
          )
        ) : this.props.appState.customerId ? (
          <div>
            <p className="col-12 text-orange">
              Please stop impersonating a customer to add a Cooksongold 3D
              library model
            </p>
          </div>
        ) : (
          dropzoneRender
        )}
      </div>
    );

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="portal-container d-md-flex align-items-center mt-5 mt-md-0">
            <div className="col-12 col-md-6 portal-contents p-4 mt-5 mt-sm-0 flex-direction-column">
              <div className="col-12">
                <h1>
                  <strong>Upload</strong>
                </h1>
                <h2 className="mt-n3">
                  Upload a model to{" "}
                  {this.props.addCustomer
                    ? " a customer"
                    : " the Cooksongold 3D"}{" "}
                  library
                </h2>

                <p>
                  The file will be automatically checked for printability issues
                  and an automatic repair will be attempted if required.
                </p>
              </div>

              {uploadFileRender}

              <div className="pt-3 d-flex flex-column flex-md-row-reverse align-items-center">
                {this.state.uploaded && !this.state.uploading && (
                  <button
                    className={
                      this.state.uploaded
                        ? "btn btn-primary cng3d-general-button highlighted"
                        : "btn btn-primary cng3d-general-button highlighted disabled"
                    }
                    onClick={this.onAnalyseModelClick}
                  >
                    Analyse Previewed Model
                  </button>
                )}
                {this.state.error !== "" && (
                  <p className="text-orange">{this.state.error}&nbsp;</p>
                )}
              </div>
            </div>

            {/* Model Viewer */}
            <div className="col-md-6 portal-rendering-engine p-4 d-none d-md-flex align-items-center text-center">
              {this.props.fileUri ? (
                <Model3dViewer
                  fileUri={this.props.fileUri}
                  fileId={this.state.customerFileId}
                  accessToken={this.props.appState.accessToken}
                  showThumbnailButton={false}
                  analysing={false}
                  key={this.state.modelViewerKey}
                  backgroundColor="#E6F0F3"
                  backgroundAlpha={0}
                />
              ) : this.state.uploading ? (
                <LoadingCube />
              ) : (
                <>
                  <img
                    className="default-icon-arrangement"
                    alt="model viewer holding"
                    src="/images/cube-elevated.svg"
                  />
                  <h2>Upload a CAD file to see a preview</h2>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
