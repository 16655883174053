import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import Api from "../../api/customer-account";
import AccountListItem from "./list-item";
import ListHeader from "../../elements/list-header";
import SearchPanel from "../../elements/search-panel";
import LoadingOverlay from "../../components/loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default class AccountList extends React.Component {
  state = {
    accounts: [],
    sortCol: "ErpCustomerReference",
    sortAsc: true,
    searchText: "",
    loading: false,
    hasMore: false,
  };

  init() {
    this.getAccounts(0);
  }

  componentDidMount() {
    if (this.props.appState.accessToken) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.appState.accessToken &&
        prevProps.appState.accessToken !== this.props.appState.accessToken) ||
      prevState.sortCol !== this.state.sortCol ||
      prevState.sortAsc !== this.state.sortAsc ||
      prevState.searchText !== this.state.searchText
    ) {
      this.init();
    }
  }

  getAccounts = async (pageNumber) => {
    const searchObject = {
      from: pageNumber * this.props.appState.pageSize,
      size: this.props.appState.pageSize,
      contains: this.state.searchText,
      filter: this.props.filter,
      orderBy: [
        {
          columnName: this.state.sortCol,
          sort: this.state.sortAsc ? "Ascending" : "Descending",
        },
      ],
    };
    this.setState({ loading: true });
    const accountsLoaded = await Api.getCustomerAccounts(
      this.props.appState.accessToken,
      searchObject
    );
    const hasMore =
      accountsLoaded && accountsLoaded.length === this.props.appState.pageSize;
    let accounts =
      pageNumber === 0
        ? accountsLoaded
        : [...this.state.accounts, ...accountsLoaded];
    this.setState({ accounts, hasMore, loading: false });
  };

  export = async () => {
    const searchObject = {
      contains: this.state.searchText,
      filter: this.props.filter,
      orderBy: [
        {
          columnName: this.state.sortCol,
          sort: this.state.sortAsc ? "Ascending" : "Descending",
        },
      ],
    };
    this.setState({ loading: true });
    await Api.exportCustomerAccounts(
      this.props.appState.accessToken,
      searchObject
    );
    this.setState({ loading: false });
  };

  onSearchSubmit = (searchText) => {
    this.setState({ searchText, accounts: [] });
  };

  onUpdateSort = (sortCol) => {
    const sortAsc =
      sortCol === this.state.sortCol ? !this.state.sortAsc : this.state.sortAsc;
    this.setState({ sortCol, sortAsc });
  };

  render() {
    const listRender =
      this.state.accounts && this.state.accounts.length > 0
        ? this.state.accounts.map((account) => (
            <AccountListItem
              key={account.customerId}
              account={account}
              cols={this.props.cols}
              filter={this.props.filter}
              onImpersonate={this.props.onImpersonate}
            />
          ))
        : !this.state.loading && (
            <div className="error">
              The search parameters returned no results
            </div>
          );

    return (
      <div className="gal3d-body font-stack-s0 enable-scroll-styling">
        <LoadingOverlay loading={this.state.loading} />
        <div className="container-fluid">
          <div className="gallery-detail-contents mt-0 mt-md-5">
            <div className="row">
              <div className="col-12 detail-contents p-4">
                <div className="row pb-4 d-flex flex-row-reverse flex-md-row">
                  <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                    <h1 className="text-center text-md-left">
                      <strong>Customers</strong>
                    </h1>
                  </div>
                  <div className="col-12 col-md-6 align-items-end justify-content-md-end justify-content-center d-flex">
                    <div className="search_container align-items-center d-flex mt-3 mt-md-5">
                      <SearchPanel onSearchSubmit={this.onSearchSubmit} />

                      <div className="col-5">
                        <div className="input-group mb-3">
                          <button
                            className="btn export_button bg-white"
                            type="button"
                            onClick={this.export}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="mr-3"
                            />
                            Export Results
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ListHeader
                  cols={this.props.cols}
                  onUpdateSort={this.onUpdateSort}
                  indented={true}
                />
                <div
                  className="row no-gutters detailed-gallery-area font-stack-s9 col-md-12 text-justify enable-scroll-styling"
                  ref={(ref) => (this.scrollParentRef = ref)}
                >
                  <InfiniteScroll
                    loadMore={this.getAccounts}
                    hasMore={this.state.hasMore && !this.state.loading}
                    getScrollParent={() => this.scrollParentRef}
                    useWindow={false}
                    className="col-12"
                  >
                    {listRender}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
