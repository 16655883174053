export function isUserAdmin(appState) {
   let isAdmin = false;
   if (!appState || !appState.groupsOfLoggedInUser) return false;
   appState.groupsOfLoggedInUser.forEach((group) => {
      if (group.groupName === process.env.REACT_APP_ADMIN_GROUP) isAdmin = true;
   });
   return isAdmin;
}

export function isUserSuperAdmin(appState) {
   let isSuperAdmin = false;
   if (!appState || !appState.groupsOfLoggedInUser) return false;
   appState.groupsOfLoggedInUser.forEach((group) => {
      if (group.groupName === process.env.REACT_APP_USER_ACCESS_ADMIN_GROUP) isSuperAdmin = true;
   });
   return isSuperAdmin;
}
