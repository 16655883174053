import React from 'react';
import NoteListItem from './note-list-item';
import Api from '../api/customer-account';
//import { Link } from 'react-router-dom';

export default class NotesList extends React.Component {
   state = { showAddNewDialog: false, newNoteText: '' };

   addNewNote = () => {
      this.setState({ showAddNewDialog: true });
   };

   onAddNewChange = (e) => {
      this.setState({ newNoteText: e.target.value });
   };

   onAddNewConfirm = async () => {
      this.setState({ loading: true });
      const success = await Api.addCustomerNote(this.props.appState.accessToken, this.props.customerId, this.state.newNoteText);
      if (success) {
         this.setState({ loading: false, showAddNewDialog: false });
         this.props.refreshAccount();
      } 
   };

   render() {
      const addNewDialog = this.state.showAddNewDialog && (
         <div className={'dialog-mask'}>
            <div className={'dialog-box'}>
               <div className='title pb-1'>Add New Note</div>
               <textarea onChange={this.onAddNewChange} maxLength={4000}/>
               <div className='left-cont'>
                  <button
                    className='btn btn-primary cng3d-general-button highlighted'
                    onClick={this.onAddNewConfirm}
                  >
                    Add New
                  </button>
               </div>
               <div className='right-cont'>
                  <button
                     className='btn btn-primary cng3d-general-button caution'
                     onClick={() => {
                        this.setState({ showAddNewDialog: false });
                     }}
                  >
                     Cancel
                  </button>
               </div>
            </div>
         </div>
      );

      let listRender = <div className='error'>There are no notes for this account</div>;

      if (!this.state.loading && this.props.notes && this.props.notes.length > 0) {
         listRender = this.props.notes.map((note) => (
            <NoteListItem
               key={note.createdDate}
               createdDate={note.createdDate}
               text={note.noteText}
               username={note.username}
            />
         ));
      }

      return (
         <div className='col-12 justify-contents-center'>
            <h2>Notes</h2>
            <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.addNewNote}>Add New Note</button>
            <div className='list-header'>
               <p className='col-3 text-center text-white'>Created Date</p>
               <p className='col-3 text-center text-white'>Username</p>
               <p className='col-6 text-center text-white'>Note Text</p>
            </div>
            <div className='note-list'>{listRender}</div>
            {addNewDialog}
         </div>
      );
   }
}
