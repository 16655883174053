import React from "react";

import ModelThumbnail from "./thumbnail";
import LoadingOverlay from "../../components/loading-overlay";
import SearchPanel from "../../elements/search-panel";

import Api from "../../api/model";

export default class GenericModelList extends React.Component {
  state = {
    models: [],
    sortCol: "Reference",
    searchText: "",
    loading: false,
    showGroupMode: false,
    showArchived: false,
    grouped: [],
    hasMore: false,
    title: "",
    modelCategories: null,
  };

  init = async () => {
    this.updateTitle();
    this.getModels();

    let modelCategories = [...this.props.appState.modelCategories];
    modelCategories.push(modelCategories.shift());
    await this.setState({ modelCategories });
  };

  componentDidMount() {
    if (this.props.appState.accessToken) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.appState.accessToken &&
        prevProps.appState.accessToken !== this.props.appState.accessToken) ||
      prevState.sortCol !== this.state.sortCol ||
      prevState.sortAsc !== this.state.sortAsc ||
      prevState.searchText !== this.state.searchText
    ) {
      this.init();
    }
  }

  updateTitle = () => {
    this.setState({ title: "Cooksongold 3D Library" });
  };

  getModels = async () => {
    const searchObject = {
      contains: this.state.searchText,
      filter: { modelType: "cad", customerId: null },
      orderBy: [
        {
          columnName: this.state.sortCol,
          sort: "Ascending",
        },
      ],
    };
    this.setState({ loading: true });
    var models = await Api.getGenericModels(searchObject);
    this.setState({ models, loading: false });
  };

  toggleArchived = (e) => {
    this.setState({ showArchived: e.target.checked });
  };

  onSearchSubmit = (searchText) => {
    this.setState({ searchText, models: [] });
  };

  render() {
    const listRender =
      this.state.modelCategories &&
      this.state.modelCategories
        .sort((a, b) => a.displayIndex - b.displayIndex)
        .map((modelCategory) => {
          return (
            <div key={"div" + modelCategory.id}>
              <h4 key={"title" + modelCategory.id}>
                <strong>{modelCategory.description}</strong>
              </h4>
              <div
                className="row font-stack-s9 col-md-12 text-justify enable-scroll-styling"
                key={"list" + modelCategory.id}
              >
                {this.state.models &&
                  (this.state.models.length > 0
                    ? this.state.models.map((model) => {
                        if (model.modelCategoryId === modelCategory.id) {
                          return (
                            <ModelThumbnail
                              appState={this.props.appState}
                              key={model.modelId}
                              model={model}
                            />
                          );
                        }
                        return null;
                      })
                    : !this.state.loading && (
                        <div className="error">
                          The search parameters returned no results
                        </div>
                      ))}
              </div>
            </div>
          );
        });

    return (
      <div className="gal3d-body font-stack-s0 enable-scroll-styling">
        <div className="container-fluid">
          <div className="gallery-detail-contents mt-0 mt-md-5">
            <div className="row">
              <div className="col-12 detail-contents p-4">
                <LoadingOverlay loading={this.state.loading} />
                <div className="row pb-4 d-flex flex-row-reverse flex-md-row">
                  <div className="col-12 col-md-7 d-flex justify-content-center justify-content-md-start align-items-center">
                    <h1 className="text-center text-md-left">
                      <strong>Cooksongold Models</strong>
                    </h1>
                  </div>
                  <div className="col-12 col-md-5 align-items-end justify-content-md-end justify-content-center d-flex">
                    <div className="search_container align-items-center d-flex mt-3 mt-md-5">
                      {/*<div className='col-4 text-right'>      
                                        </div>*/}

                      <SearchPanel onSearchSubmit={this.onSearchSubmit} />
                    </div>
                  </div>
                </div>

                <div className="detailed-gallery-area font-stack-s9 col-md-12 text-justify enable-scroll-styling">
                  {listRender}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
