import Api from "./api";

export default class ApiLists extends Api {
  static async getModelCategories() {
    const data = await this.get("/api/List/ModelCategory?args=ADDIDZERO", null);
    return data;
  }

  static async getMaterials() {
    const data = await this.get("/api/List/Material", null);
    return data;
  }

  static async getPrinters() {
    const data = await this.get("/api/List/Printer", null);
    return data;
  }
}
