import React from 'react';
import { GetMultilineString } from '../utils/string';

let loadingTimeout;

export default class LoadingOverlay extends React.Component {
   state = { 
      loading: false,
      message: '',
      messageTimer: setTimeout(null),
   };

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.loading === this.props.loading && prevState.loading === this.state.loading) return;
      if (this.props.uploading && prevProps.uploading === this.props.uploading && prevState.uploading === this.state.uploading) return;
      if (!this.props.loading) {
         clearTimeout(loadingTimeout);
         clearTimeout(this.state.messageTimer);
         this.setState({ loading: false });
      } else {
         if (this.props.uploading) {
            this.setState({
               messageTimer: setTimeout(() => 
               this.setState({ message:'\nPlease bear with us, uploading model' }), 30000)
            })
         }
         loadingTimeout = setTimeout(() => {
            this.setState({ loading: true });
         }, 500);
      }
   }

   render() {
      const displayText = this.props.displayText || "Loading...";
      const visibleClass = this.state.loading ? ' visible' : '';
      const visibleNegativeClass = this.state.loading ? ' visible negative' : '';
      const display = (
         <div className={`loading-overlay${visibleClass}`}>
            <div className={`loading-box${visibleClass}`}>
               <h4 className={visibleClass}>{displayText}</h4>
               <div className={`spinner${visibleClass}`}></div>
               <h4 className={visibleNegativeClass}>{GetMultilineString(this.state.message)}</h4>
            </div>
         </div>
      );
      return <div> {display}</div>;
   }
}
