import React from 'react';
import { GetUrlFromAzureStorage } from '../../utils/images';
import STLViewer from './stl-viewer';

export default class Model3dViewer extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         currentKey: true,
         setInitialThumbnail: true,
      }

      this.stlViewer = React.createRef();
      this.scale = this.scale.bind(this);
   }

   componentDidUpdate(prevProps) {
      if (prevProps.fileUri !== this.props.fileUri || prevProps.showSizeGrid !== this.props.showSizeGrid) {
         this.setState({ currentKey: !this.state.currentKey })
      }
   }

   scale(scaleFactor) {
       this.stlViewer.current.scale(scaleFactor)
   }
   
   onSetInitialThumbnail = async (e) => {
      await this.setState({ setInitialThumbnail: e })
   }

   render() {
      return (
        <STLViewer
            sceneClassName='test-scene'
            url={GetUrlFromAzureStorage(this.props.fileUri)}
            className='model-3d-viewer'
            rotate='true'
            fileId={this.props.fileId}
            accessToken={this.props.accessToken}
            showThumbnailButton={this.props.showThumbnailButton}
            key={this.state.currentKey}
            analysing={this.props.analysing}
            setInitialThumbnail={this.state.setInitialThumbnail}
            onSetInitialThumbnail={this.onSetInitialThumbnail}
            ref={this.stlViewer}
            showSizeGrid={this.props.showSizeGrid}
            backgroundColor={this.props.backgroundColor}
            backgroundAlpha={this.props.backgroundAlpha}
        />
      );
   }
}
