import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

export default class ListHeader extends React.Component {
   render() {
      const headerRender = this.props.cols.map((col) =>
         (col.dbName !== 'N/A') ? (
            <p key={col.name} className={`col-${col.width} text-center`} onClick={() => this.props.onUpdateSort(col.dbName)}>
               {col.name}&nbsp;
               <FontAwesomeIcon icon={faSort} />
            </p>
         ) : (
            <p key={col.name} className={`col-${col.width}`}>
               {col.name}
            </p>
         )
      );

      let className = this.props.onUpdateSort ? 'with-link' : '';
      className += this.props.indented === true ? ' indented' : '';

      return <div className={`list-header ${className}`}>{headerRender}</div>;
   }
}
