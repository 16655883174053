import React from "react";

import AccountList from "./list-base";

export default class AccountListLive extends React.Component {
  state = {
    cols: [
      { width: 3, name: "Account Number", dbName: "ErpCustomerReference" },
      { width: 3, name: "Customer Name", dbName: "ErpCustomerName" },
      { width: 3, name: "Portal First Used", dbName: "CreatedDate" },
      { width: 3, name: "Portal Last Used", dbName: "LastLoginDate" },
    ],
    filter: {
      CustomerState: "Live",
      isLoggedInCustomer: "true",
    },
  };

  render() {
    return (
      <AccountList
        appState={this.props.appState}
        title="Live Accounts"
        cols={this.state.cols}
        filter={this.state.filter}
        onImpersonate={this.props.onImpersonate}
      />
    );
  }
}
