import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import STLLoaderModule from 'three-stl-loader';
import LoadingCube from '../../elements/loading-cube';
//import { ScaleLoader } from 'react-spinners';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader.js';
import ApiFile from '../../api/file';
import scrubIcon from '../../images/scrub-icon.png';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import helvetiker_regular from 'three/examples/fonts/helvetiker_regular.typeface.json';

const STLLoader = STLLoaderModule(THREE);

export default class STLViewer extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         currentRender: '',
         viewReset: false,
         setThumbnail: false,
         selectedFile: null,
         buttonHeightAdjustment: this.props.showThumbnailButton ? 140 : 75,
         node: null,
         scale: false,
         scaleFactor: 1,
      };

      this.topViewClick = this.topViewClick.bind(this);
      this.frontViewClick = this.frontViewClick.bind(this);
      this.rightViewClick = this.rightViewClick.bind(this);
      this.perspectiveViewClick = this.perspectiveViewClick.bind(this);
      this.setThumbnailClick = this.setThumbnailClick.bind(this);
      this.scale = this.scale.bind(this);
   }

   static propTypes = {
      className: PropTypes.string,
      url: PropTypes.string,
      file: PropTypes.object,
      width: PropTypes.number,
      height: PropTypes.number,
      backgroundColor: PropTypes.string,
      modelColor: PropTypes.string,
      sceneClassName: PropTypes.string,
      onSceneRendered: PropTypes.func,
      fileId: PropTypes.number,
      accessToken: PropTypes.string,
      showThumbnailButton: PropTypes.bool,
   };

   static defaultProps = {
      backgroundColor: '#FFF',
      backgroundAlpha: 1,
      modelColor: '#FFF',
      height: window.innerWidth*0.375*345/410,
      width: window.innerWidth*0.375,
      rotate: true,
      trackballControls: true,
      sceneClassName: '',
      showThumbnailButton: true,
      fileId: null,
      showSizeGrid: false,
      gridHeight: 100,
      gridWidth: 100,
      gridLineVerticalSpacing: 10,
      gridLineHorizontalSpacing: 10,
      gridLabelVerticalSpacing: 10,
      gridLabelHorizontalSpacing: 10,
      enableCheckingMaxDimensions: false,
      maxSizeX: 100,
      maxSizeY: 100,
      maxSizeZ: 90,
   };

   componentDidMount() {
      this.renderModel(this.props);
      this.setState({ node: this.wrapper.current })
   }

   wrapper = createRef();

   renderModel(props) {
      let camera, scene, renderer, mesh, sizeGridGroup, axisGroup, distance, controls, cameraZ, shifts,
          gridHeight, gridWidth, gridLineVerticalSpacing, gridLineHorizontalSpacing, gridLabelVerticalSpacing, gridLabelHorizontalSpacing,
          points, gridLineGeometry, gridLineWireframe, gridLineMaterial ,textParameters, verticalAxisTitle, horizontalAxisTtile;

      const {
         url,
         file,
         width,
         height,
         modelColor,
         backgroundColor,
         backgroundAlpha,
         trackballControls,
         sceneClassName,
         onSceneRendered,
      } = props;
      
      // array to store separate geometeries loaded from a Rhino file
      var bufferGeometries = [];

      // rotation iniial settings
      var autoRotation = true;
      var zoomLevel = 30;
      var zoomLevelFinish = 1.0;
      this.setState({
         currentRender: this.props.showSizeGrid ? 'front' : 'perspective',
      });

      scene = new THREE.Scene();
      distance = 10000;
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
      directionalLight.position.x = 0;
      directionalLight.position.y = 1;
      directionalLight.position.z = 0;
      directionalLight.position.normalize();
      scene.add(directionalLight);

      const ambientLight = new THREE.AmbientLight(0x404040); // soft white light
      scene.add(ambientLight);

      // add geometeries from a Rhino file to array
      const mergeGeometry = (geometry) => {
         geometry.children.forEach(child => {
            if (child.children.length === 0) {
               if (child.type === 'Mesh' && child.visible === true) {
                  child.geometry.deleteAttribute( 'uv' );
                  bufferGeometries.push(child.geometry);
               }
            } else {
               mergeGeometry(child);
            }
         })
      }

      const onLoad = (geometry) => {
         // Size Grid
         gridHeight = this.props.gridHeight;
         gridWidth = this.props.gridWidth;
         gridLineVerticalSpacing = this.props.gridLineVerticalSpacing;
         gridLineHorizontalSpacing = this.props.gridLineHorizontalSpacing;
         gridLabelVerticalSpacing = this.props.gridLabelVerticalSpacing;
         gridLabelHorizontalSpacing = this.props.gridLabelHorizontalSpacing;

         sizeGridGroup = new THREE.Group();
         axisGroup = new THREE.Group();

         var gridLineMaterialLabel = new THREE.LineBasicMaterial({
            color: 0x169bd7,
            opacity: 0.25, 
            transparent: true
            })

        var gridLineMaterialNoLabel = new THREE.LineBasicMaterial({
            color: 0x169bd7,
            opacity: 0.1, 
            transparent: true
            })

         // Create Vertical Axis Grid Lines
         for (var hLine = 0; hLine <= gridHeight/gridLineVerticalSpacing; hLine++) {
            points = [];
            points.push( new THREE.Vector3(-gridHeight/2, 0));
            points.push( new THREE.Vector3(gridHeight/2, 0));

            gridLineGeometry = new THREE.BufferGeometry().setFromPoints( points );

            gridLineMaterial = (hLine*gridLineVerticalSpacing)%gridLabelVerticalSpacing === 0 ? gridLineMaterialLabel : gridLineMaterialNoLabel;
            gridLineWireframe = new THREE.LineSegments(gridLineGeometry, gridLineMaterial);

            gridLineWireframe.rotateX(Math.PI/2);
            gridLineWireframe.position.set(0, 0, hLine*gridLineVerticalSpacing-gridHeight/2);
            sizeGridGroup.add(gridLineWireframe);
         }
         
         // Create Horizontal Axis Grid Lines
         for (var wLine = 0; wLine <= gridWidth/gridLineHorizontalSpacing; wLine++) {
            points = [];
            points.push( new THREE.Vector3(0, -gridWidth/2, 0));
            points.push( new THREE.Vector3(0, gridWidth/2, 0));

            gridLineGeometry = new THREE.BufferGeometry().setFromPoints( points );

            gridLineMaterial = (wLine*gridLineHorizontalSpacing)%gridLabelHorizontalSpacing === 0 ? gridLineMaterialLabel : gridLineMaterialNoLabel;
            gridLineWireframe = new THREE.LineSegments(gridLineGeometry, gridLineMaterial);

            gridLineWireframe.rotateX(Math.PI/2);
            gridLineWireframe.position.set(wLine*gridLineHorizontalSpacing-gridWidth/2, 0, 0);
            sizeGridGroup.add(gridLineWireframe);
         }

         sizeGridGroup.rotation.set(-0.8, 0, 0.5);
         sizeGridGroup.visible = this.props.showSizeGrid;

         // Add Height Measurements
         var fontLoader = new THREE.FontLoader();
         var heightRulerGroup = new THREE.Group();
         var widthRulerGroup = new THREE.Group();

         axisGroup.visible = this.props.showSizeGrid;
         var font = fontLoader.parse(helvetiker_regular)
         
         if (font) {
             var  textMaterial = new THREE.MeshBasicMaterial({ color: 0xfbab00 });
             var fontSize = 3;
             var fontThickness = 0.1;
             textParameters = {size: fontSize, height: fontThickness, curveSegments: 6, font: font};

             // Generate Vertical Axis Labels
             for (var hLabel = 0; hLabel <= gridHeight/gridLabelVerticalSpacing; hLabel++) {
                var verticalLabelText = new THREE.Mesh(new THREE.TextGeometry((hLabel*gridLabelVerticalSpacing).toString(), textParameters), textMaterial);
                heightRulerGroup.add(verticalLabelText);

                // Right-Align Text
                var verticalLabelTextBoundingBox = new THREE.Box3();
                verticalLabelTextBoundingBox.setFromObject(verticalLabelText);
                var verticalLabelTextShift = verticalLabelTextBoundingBox.max.x;
                verticalLabelText.geometry.translate(-verticalLabelTextShift, 0, 0);
                verticalLabelText.geometry.verticesNeedUpdate = true;

                // Position Text
                verticalLabelText.translateY(hLabel*gridLabelVerticalSpacing-gridHeight/2);
             }

             heightRulerGroup.translateX(-gridWidth/2-3);
             heightRulerGroup.translateY(-fontSize/2);

             // Generate Horizontal Axis Labels
             for (var wLabel = 0; wLabel <= gridWidth/gridLabelHorizontalSpacing; wLabel++) {
                var horizontalLabelText = new THREE.Mesh(new THREE.TextGeometry((wLabel*gridLabelHorizontalSpacing).toString(), textParameters), textMaterial);
                widthRulerGroup.add(horizontalLabelText);

                // Centre-Align Text
                var horizontalLabelTextBoundingBox = new THREE.Box3();
                horizontalLabelTextBoundingBox.setFromObject(horizontalLabelText);
                var horizontalLabelTextShift = horizontalLabelTextBoundingBox.max.x;
                horizontalLabelText.geometry.translate(-horizontalLabelTextShift/2, 0, 0);
                horizontalLabelText.geometry.verticesNeedUpdate = true;
                
                // Position Text
                horizontalLabelText.translateX(wLabel*gridLabelHorizontalSpacing-gridWidth/2);
             }

             widthRulerGroup.translateY(-gridHeight/2-6);

             // Add Dimension + Unit Labels
             verticalAxisTitle = new THREE.Mesh(new THREE.TextGeometry('   Z\n(mm)', textParameters), textMaterial);
             horizontalAxisTtile = new THREE.Mesh(new THREE.TextGeometry('X (mm)', textParameters), textMaterial);

             verticalAxisTitle.translateX(-gridWidth/2-17-fontSize/2);
             horizontalAxisTtile.translateY(-gridHeight/2-11-fontSize/2);
             horizontalAxisTtile.translateX(-7.5)

             // Add Axes to Group Object
             axisGroup.add(heightRulerGroup);
             axisGroup.add(widthRulerGroup);
             axisGroup.add(verticalAxisTitle);
             axisGroup.add(horizontalAxisTtile);

             axisGroup.rotation.set(-0.8, 0, 0.5);
             axisGroup.rotateX(Math.PI /2);
         }

         // Mesh
         if (url.includes('.3dm')) {
            mergeGeometry(geometry);
            geometry = new THREE.Geometry().fromBufferGeometry(BufferGeometryUtils.mergeBufferGeometries(bufferGeometries));
         }

         geometry.computeFaceNormals();
         geometry.computeVertexNormals();
         geometry.center();

         mesh = new THREE.Mesh(
            geometry,
            new THREE.MeshPhysicalMaterial({
                color: modelColor,
                clearcoat: 0.25,
                clearcoatRoughness: 0.4,
            })
         );
        
         if (this.props.showSizeGrid) {
            shifts = originToBottom(mesh.geometry);
            mesh.translateX(-gridWidth/2);
            mesh.translateY(-gridHeight/2);
            mesh.translateZ(shifts.y*2);

            // Colour Model If Larger Than Maximum Allowed Size
            if (this.props.enableCheckingMaxDimensions) {
                if (shifts.x*2 > this.props.maxSizeX || 
                    shifts.y*2 > this.props.maxSizeY || 
                    shifts.z*2 > this.props.maxSizeZ) 
                {
                    mesh.material.color.setHex(0xFF6347);
                } else {
                    mesh.material.color.setHex(0xffffff);
                }
             }
         }
         mesh.rotation.set(-0.8, 0, 0.5);
         
         // Add objects to scene
         scene.add(mesh);
         scene.add(axisGroup);
         scene.add(sizeGridGroup);

         // Camera
         var boundingBox = new THREE.Box3();
         boundingBox.setFromObject(this.props.showSizeGrid ? sizeGridGroup : mesh);
         var sphere = boundingBox.getBoundingSphere(this.props.showSizeGrid ? sizeGridGroup : mesh);

         cameraZ = sphere.radius / Math.sin((Math.PI * 45) / 360);

         var aspectRatio = width / height;
         if (this.props.showSizeGrid) {
            var orthoHeight = Math.max(gridHeight, gridWidth)/2+13;
            camera = new THREE.OrthographicCamera(-orthoHeight*aspectRatio, orthoHeight*aspectRatio, orthoHeight, -orthoHeight, -shifts.y*2, distance);
         } else {
            camera = new THREE.PerspectiveCamera(40, aspectRatio, 1, distance);
            camera.position.set(0, 0, zoomLevel * cameraZ);
         }
         scene.add(camera);
         directionalLight.position.copy(camera.position);

         renderer = new THREE.WebGLRenderer({
            preserveDrawingBuffer: true,
            antialias: true,
            alpha: true,
         });
         renderer.setSize(width, height);
         renderer.setClearColor(backgroundColor, backgroundAlpha);
         renderer.domElement.className = sceneClassName;
         renderer.domElement.id = 'original-canvas';

         this.state.node.replaceChild(renderer.domElement, this.state.node.firstChild);

         render();

         if (typeof onSceneRendered === 'function') {
            onSceneRendered(this.state.node);
         }

         if (trackballControls) {
            controls = new TrackballControls(camera, renderer.domElement);

            controls.enabled = false;
            controls.rotateSpeed = 3.0;
            controls.zoomSpeed = 3.0;
            controls.panSpeed = 1.0;
            controls.staticMoving = true;

            if (this.props.showSizeGrid) controls.noRotate = true;

            viewRender();
         }
      };

      const onProgress = (xhr) => {
         // if (xhr.lengthComputable) {
         //     let percentComplete = xhr.loaded / xhr.total * 100;
         // }
      };

      let loader;
      if (url.includes('.3dm')) {
         loader = new Rhino3dmLoader();
         loader.setLibraryPath( 'http://cdn.jsdelivr.net/npm/rhino3dm@0.13.0/' );
      } else {
         loader = new STLLoader();
      }

      if (file) {
         loader.loadFile(file, onLoad, onProgress);
      } else {
         loader.load(url, onLoad, onProgress);
      }

      const render = () => {
         renderer.render(scene, camera);
      };

      // Turn auto rotate off
      function autoRotationOff() {
         autoRotation = false;
      }

      const viewRender = () => {
         if (this.state.currentRender === 'top') {
            mesh.rotation.set(0, 0, 0);
            sizeGridGroup.rotation.set(-Math.PI / 2, 0, 0);
            axisGroup.rotation.set(0, 0, 0);
         } else if (this.state.currentRender === 'front') {
            mesh.rotation.set(-Math.PI / 2, 0, 0);
            sizeGridGroup.rotation.set(-Math.PI / 2, 0, 0);
            axisGroup.rotation.set(0, 0, 0);
         } else if (this.state.currentRender === 'right') {
            mesh.rotation.set(-Math.PI / 2, 0, -Math.PI / 2);
            sizeGridGroup.rotation.set(-Math.PI / 2, 0, 0);
            axisGroup.rotation.set(0, 0, 0);
         } else if (this.state.currentRender === 'perspective' && this.state.viewReset === true) {
            mesh.rotation.set(-0.8, 0, 0.5);
            sizeGridGroup.rotation.set(-0.8, 0, 0.5);
            axisGroup.rotation.set(-0.8, 0, 0.5);
            axisGroup.rotateX(Math.PI / 2);
            autoRotation = true;
         }

         // Reset View
         if (this.state.viewReset === true) {
            controls.reset();
            camera.position.set(0, 0, zoomLevelFinish * cameraZ);

            if (this.state.currentRender === 'top') {
                verticalAxisTitle.geometry = new THREE.TextGeometry('   Y\n(mm)', textParameters);
                horizontalAxisTtile.geometry = new THREE.TextGeometry('X (mm)', textParameters);
            } else if (this.state.currentRender === 'front') {
                verticalAxisTitle.geometry = new THREE.TextGeometry('   Z\n(mm)', textParameters);
                horizontalAxisTtile.geometry = new THREE.TextGeometry('X (mm)', textParameters);
            } else if (this.state.currentRender === 'right') {
                verticalAxisTitle.geometry = new THREE.TextGeometry('   Z\n(mm)', textParameters);
                horizontalAxisTtile.geometry = new THREE.TextGeometry('Y (mm)', textParameters);
            }
            
            this.setState({ viewReset: false });
         }

         // Scale Mesh
         if (this.state.scale) {
             mesh.scale.set(this.state.scaleFactor, this.state.scaleFactor, this.state.scaleFactor);

             // Repositon Mesh Above Grid In Top View
             if (this.props.showSizeGrid) {
                mesh.position.z = (shifts.y*2*this.state.scaleFactor);
             }

             // Colour Model If Larger Than Maximum Allowed Size
             if (this.props.enableCheckingMaxDimensions) {
                if (shifts.x*2*this.state.scaleFactor > this.props.maxSizeX || 
                    shifts.y*2*this.state.scaleFactor > this.props.maxSizeY || 
                    shifts.z*2*this.state.scaleFactor > this.props.maxSizeZ) 
                {
                    mesh.material.color.setHex(0xFF6347);
                } else {
                    mesh.material.color.setHex(0xffffff);
                }
             }

             this.setState({ scale: false });
         }

         if (this.state.setThumbnail === true) {
            saveAsImage();
            this.setState({ setThumbnail: false });
         }

         // Zoom in to mesh
         if (zoomLevel > zoomLevelFinish) {
            requestAnimationFrame(zoomRender);
            zoomLevel -= zoomLevel ** 3 / (zoomLevel ** 3 + 60);
         } else {
            if (this.props.setInitialThumbnail && this.props.analysing) {
               saveAsImage();
               this.props.onSetInitialThumbnail(false);
            }


            controls.enabled = true;
            if (this.state.currentRender === 'perspective') {
               // Enable controls and add listeners
               renderer.domElement.addEventListener('pointerdown', autoRotationOff, false);

               // Update animation frame
               if (autoRotation) {
                  requestAnimationFrame(autoRotateRender);
               } else {
                  requestAnimationFrame(viewRender);
               }
            } else {
               // Update animation frame
               requestAnimationFrame(viewRender);
            }
         }
         // Move light wih camera
         directionalLight.position.copy(camera.position);
         controls.update();
         render();
      };

      // Generate auto zoom animation frame
      const zoomRender = () => {
         camera.position.set(0, 0, zoomLevel * cameraZ);
         requestAnimationFrame(viewRender);
      };

      // Generate auto rotate animation frame
      const autoRotateRender = () => {
         mesh.rotateZ(0.75*(Math.PI/180));
         requestAnimationFrame(viewRender);
      };

      var saveAsImage = async () => {
         var strMime = 'image/jpeg';
         var dimensions = Math.max(height, width);

         // create 2nd canvas which is square
         var resizedCanvas = document.createElement('canvas');
         var resizedContext = resizedCanvas.getContext('2d');
         resizedCanvas.height = dimensions;
         resizedCanvas.width = dimensions;

         // get current view from render
         var canvas = document.getElementById('original-canvas');

         // add current view to new canvas
         resizedContext.drawImage(canvas, 0, 0, dimensions, dimensions);
         var imgData = resizedCanvas.toDataURL(strMime);

         // convert to file
         var fileData = dataURItoFile(imgData);

         // upload image to API
         const formData = new FormData();
         formData.append('filePayload', fileData);

         await ApiFile.uploadCadImage(this.props.accessToken, this.props.fileId, formData);
      };

      function dataURItoFile(dataURI) {
         var byteString = atob(dataURI.split(',')[1]);
         var ab = new ArrayBuffer(byteString.length);
         var ia = new Uint8Array(ab);
         for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
         }
         var blob = new Blob([ab], { type: 'image/jpeg' });
         var file = new File([blob], 'canvasImage.jpg', { type: 'image/jpeg' });
         return file;
      }

      function originToBottom ( geometry ) {
        var shiftX = geometry.boundingBox ? geometry.boundingBox.min.x : geometry.computeBoundingBox().min.x;
        var shiftY = geometry.boundingBox ? geometry.boundingBox.min.y : geometry.computeBoundingBox().min.y;
        var shiftZ = geometry.boundingBox ? geometry.boundingBox.min.z : geometry.computeBoundingBox().min.z;

        // Origin to bottom left corner of mesh
        geometry.translate( -shiftX, -shiftY, -shiftZ);

        //finally
        geometry.verticesNeedUpdate = true;

        var shifts = {x: -shiftX, y: -shiftY, z: -shiftZ}
        return shifts;
      }

      window.addEventListener( 'resize', onWindowResize, false );

      function onWindowResize(){
          renderer.setSize( window.innerWidth*0.375, window.innerWidth*0.375*345/410 );
          var canvas = renderer.domElement;
          camera.aspect = canvas.width/canvas.height;
          camera.updateProjectionMatrix();
      
      }
   }

   shouldComponentUpdate(nextProps, nextState) {
      if (JSON.stringify(nextProps) === JSON.stringify(this.props)) {
         return false;
      }
      return true;
   }

   componentDidCatch(error, info) {
      console.log(error, info);
   }

   topViewClick() {
      this.setState({ currentRender: 'top', viewReset: true });
   }
   frontViewClick() {
      this.setState({ currentRender: 'front', viewReset: true });
   }
   rightViewClick() {
      this.setState({ currentRender: 'right', viewReset: true });
   }
   perspectiveViewClick() {
      this.setState({ currentRender: 'perspective', viewReset: true });
   }
   setThumbnailClick() {
      this.setState({ setThumbnail: true });
   }
   scale(scaleFactor) {
       this.setState({ scale: true, scaleFactor: scaleFactor*this.state.scaleFactor})
   }

   render() {
      return (
         <div
            className={this.props.className}
            ref={this.wrapper}
         >
            <div
               style={{
                  height: this.props.height,
                  width: this.props.width,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
            <LoadingCube/>
            </div>
            <div className='scrub-icon'>
               <img src={scrubIcon} alt="3D Scrub Icon" />
            </div>
            <div className='buttons'>
               <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.topViewClick}>Top</button>
               <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.frontViewClick}>Front</button>
               <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.rightViewClick}>Right</button>
               {!this.props.showSizeGrid && <button className='btn btn-primary btn-primary cng3d-general-button highlighted' onClick={this.perspectiveViewClick}>Reset</button>}
            </div>
            {this.props.showThumbnailButton === true ? (
               <div className='buttons'>
                  <button className='btn btn-primary cng3d-general-button highlighted' onClick={this.setThumbnailClick}>
                     Set Thumbnail
                  </button>
               </div>
            ) : null}
         </div>
      );
   }
}
